import React from 'react';
import { useAssignmentContext } from '../assignments/assignmentContext';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { PhaseCompletedLottie } from '../components/medal/PhaseCompleted';
import { Tooltip } from '../../../lib/Tooltip';
import { Progress } from 'reactstrap';
import { Session } from '../../../domain/Session';
import { gotoRoute } from '../../route';

export const CoursePhase = ({ phase }) => {
  const assignmentContext = useAssignmentContext();

  const phaseTasks = _.compact(
    _.flatMap(phase?.practices.map((p) => p?.tasks))
  );
  const { t } = useTranslation('module.development');

  const phaseProgressText = () => {
    return `${assignmentContext.getCompletionStatusText(phaseTasks)} ${t(
      'module.development.tasks:tasks'
    )}`;
  };
  const progressPercentage =
    assignmentContext.getCompletionStatusPercentage(phaseTasks);
  const isCompleted = assignmentContext.getCompletionStatusBoolean(phaseTasks);
  const notification = assignmentContext.getActionStatus(phaseTasks);

  return (
    <div className="learning-line-card-wrapper">
      <div className="card">
        {phase.image && <img src={phase.image} />}
        <div className="card-body flex-grow-0 card-body--title">
          <h5>{phase.name}</h5>
          <p>{phaseProgressText()}</p>
          {isCompleted && (
            <div className={'phase-medal'}>
              <PhaseCompletedLottie />
            </div>
          )}
        </div>
        <Progress value={progressPercentage} />
        {notification &&
          notification !== 'empty' &&
          notification !== 'accepted' && (
            <div
              className={`learning-line-card-notification notification--${notification}`}
            >
              <Tooltip
                tooltip={t(`module.development.tasks:${notification}`)}
              ></Tooltip>
            </div>
          )}
        <div
          className="card-body"
          data-learningline-id={
            assignmentContext.getLearningLine().learningLineId
          }
        >
          <div className="learning-indicators-group">
            {phase.practices.map((practice) => {
              return (
                <CoursePractice practice={practice} key={practice.practiceId} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const CoursePractice = ({ practice }) => {
  const assignmentContext = useAssignmentContext();
  const status = assignmentContext.getActionStatus(practice.tasks);
  const isTeacher = assignmentContext.isTeacher;
  const { t } = useTranslation('module.development');

  const openTask = () => {
    isTeacher && assignmentContext.refresh();
    let routeArgs = {
      taskId: assignmentContext.getNextTaskFromPracticeId(practice.practiceId)
        .taskId,
      learningLineId: assignmentContext.getLearningLine().learningLineId,
    }; // Refresh content on opening for teachers
    if (routeArgs.taskId) {
      gotoRoute('development.course-detail', routeArgs);
    }
  };
  const notification = assignmentContext.getActionStatus(practice.tasks);

  return (
    <div
      className={'learning-indicator'}
      data-indicator-id={practice.practiceId}
    >
      <div className={`learningline-status-indicator status--${status}`}>
        <div className="learning-indicator__top">
          <div className="learning-indicator__children">
            {practice.name}{' '}
            <span className="small">
              {practice?.tasks.length > 0 &&
                assignmentContext.getCompletionStatusText(practice.tasks)}{' '}
              {t('module.development.tasks:tasks')}
            </span>
          </div>
          <div className="icon clickable" onClick={openTask}>
            <Icon indicatorStatus={status} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Icon = ({ indicatorStatus }) => {
  const assignmentContext = useAssignmentContext();
  const isTrainee = assignmentContext.isTrainee;
  const tooltipTranslations = !isTrainee ? 'forTeacher' : 'forTrainee';
  const { t } = useTranslation();

  if (!indicatorStatus) {
    return (
      <Tooltip
        tooltip={t(`module.development.tasks:${tooltipTranslations}.empty`)}
      >
        <span className={'i-add i-dark'} />
      </Tooltip>
    );
  }

  if (indicatorStatus.indexOf('submitted') !== -1) {
    return (
      <Tooltip
        tooltip={t(`module.development.tasks:${tooltipTranslations}.submitted`)}
      >
        <span className={'i-submitted i-dark'} />
      </Tooltip>
    );
  } else if (indicatorStatus.indexOf('rejected') !== -1) {
    return (
      <Tooltip
        tooltip={t(`module.development.tasks:${tooltipTranslations}.rejected`)}
      >
        <span className={'i-warning i-light'} />
      </Tooltip>
    );
  } else if (indicatorStatus.indexOf('changes_requested') !== -1) {
    return (
      <Tooltip
        tooltip={t(
          `module.development.tasks:${tooltipTranslations}.changes_requested`
        )}
      >
        <span className={'i-warning i-light'} />
      </Tooltip>
    );
  } else if (indicatorStatus.indexOf('accepted') !== -1) {
    return (
      <Tooltip
        tooltip={t(`module.development.tasks:${tooltipTranslations}.accepted`)}
      >
        <span className={'i-check i-light'} />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        tooltip={t(`module.development.tasks:${tooltipTranslations}.empty`)}
      >
        <span className={'i-add i-dark'} />
      </Tooltip>
    );
  }
};
