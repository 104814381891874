import React, { useEffect, useState } from 'react';
import { Scoreboard } from '../../../remotion/overlays/scoreboard';
import { AbsoluteFill, Internals } from 'remotion';

import SportingEventCollection from 'domain/SportingEvent';
import TeamCollection from 'domain/Team';

import { MatchClockHelper } from 'domain/ObservationLogger';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { gotoPath } from '../../route';

import Stats from '../../../remotion/overlays/stats';
import { NotFound } from '../../../lib/PlaceHolder';

/*
https://github.com/remotion-dev/remotion/blob/89db17200c834e998ee54bb9f4e4b254595b687c/packages/player/src/SharedPlayerContext.tsx#L113
 */
const PLAYER_COMP_ID = 'player-comp';
const FPS = 25;

const compositionManagerContext = {
  compositions: [
    {
      component: () => <div>hi</div>,
      durationInFrames: FPS * 3600 * 10,
      height: window.innerHeight,
      width: window.innerWidth,
      fps: FPS,
      id: PLAYER_COMP_ID,
      props: {},
      nonce: 777,
      scale: 1,
      folderName: null,
      defaultProps: undefined,
      parentFolderName: null,
      schema: null,
      calculateMetadata: null,
    },
  ],
  folders: [],
  registerFolder: () => undefined,
  unregisterFolder: () => undefined,
  currentComposition: PLAYER_COMP_ID,
  registerComposition: () => undefined,
  registerSequence: () => undefined,
  sequences: [],
  setCurrentComposition: () => undefined,
  unregisterComposition: () => undefined,
  unregisterSequence: () => undefined,
  registerAsset: () => undefined,
  unregisterAsset: () => undefined,
  currentCompositionMetadata: null,
  setCurrentCompositionMetadata: () => undefined,
  assets: [],
  setClipRegion: () => undefined,
  resolved: null,
  canvasContent: {
    type: 'composition',
    compositionId: PLAYER_COMP_ID,
  },
};

const useTime = (interval) => {
  const [currentTime, setTime] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, interval);
    return () => {
      clearInterval(intervalId);
    };
  }, [interval]);
  return currentTime;
};
export const MatchLivestreamOverview = ({
  match: {
    params: { sportingEventId },
  },
}) => {
  return (
    <ul>
      <li>
        <NavLink to={`/match/livestream/${sportingEventId}/scoreboard`}>
          Scoreboard overlay
        </NavLink>
      </li>
      <li>
        <NavLink to={`/match/livestream/${sportingEventId}/stats`}>
          Stats overlay
        </NavLink>
      </li>
    </ul>
  );
};

const useReport = (sportingEventId, reportId, refreshInterval = 1000) => {
  // TODO: use react-query. This should be replaced by a special livestream report which includes all
  //       current time, server timestamp and scores.
  const [teams, setTeams] = useState(null);

  // Make sure the component gets refreshed
  const wallTime = useTime(refreshInterval);
  const [data, setData] = useState(null);

  useEffect(() => {
    let interval = null;
    (async () => {
      // This part doesn't need to be executed when the sportingEventId changes, but when
      // data is already there the fetchIfEmpty will prevent unneeded re-fetching
      await Promise.all([
        SportingEventCollection.fetchIfEmpty(),
        TeamCollection.fetchIfEmpty(),
      ]);

      const sportingEvent = SportingEventCollection.get(sportingEventId);
      setTeams({
        $homeTeam: sportingEvent.homeTeam.toJS(),
        $awayTeam: sportingEvent.awayTeam.toJS(),
      });

      const report = await sportingEvent.getReport(reportId);
      setData({
        timeFn: MatchClockHelper.getCurrentTimeFn(
          sportingEvent,
          'main',
          null,
          true
        ),
        report,
      });

      let isBusy = false;

      interval = setInterval(async () => {
        // When we refresh the data the objects attributes within `data` get changed.
        // the objects itself in `data` are not changed.
        if (isBusy) {
          return;
        }
        isBusy = true;
        await Promise.all([
          sportingEvent.fetch({ data: { metadata: 'false' } }),
          report.fetch(),
        ]);
        setData({
          timeFn: MatchClockHelper.getCurrentTimeFn(
            sportingEvent,
            'main',
            null,
            true
          ),
          report,
        });

        if (sportingEvent.ended()) {
          clearInterval(interval);
        }
        isBusy = false;
      }, refreshInterval);
    })();
    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [sportingEventId]);

  return {
    teams,
    data,
  };
};

const ScoreboardOverlay = ({ sportingEventId }) => {
  const { data, teams } = useReport(sportingEventId, 'score');

  if (data === null) {
    return 'Loading...';
  }

  const { report: scoreReport, timeFn } = data;

  const homeScore = scoreReport.c(
    `team_id:${teams.$homeTeam.teamId}.score`
  ).count;
  const awayScore = scoreReport.c(
    `team_id:${teams.$awayTeam.teamId}.score`
  ).count;

  const [periodId, time] = timeFn();

  let currentTime = parseInt(time);
  if (isNaN(currentTime)) {
    currentTime = 0;
  }

  const clip = {
    $sportingEvent: {
      events: [
        {
          clock_time: currentTime - 1,
          video_time: currentTime - 1,
          period: periodId,
          home_score: homeScore,
          away_score: awayScore,
        },
        {
          clock_time: currentTime,
          video_time: currentTime,
          period: periodId,
          home_score: homeScore,
          away_score: awayScore,
        },
        {
          clock_time: currentTime + 1,
          video_time: currentTime + 1,
          period: periodId,
          home_score: homeScore,
          away_score: awayScore,
        },
      ],
      ...teams,
    },
    start: 0,
  };

  const timelineContext = {
    frame: {
      [PLAYER_COMP_ID]: currentTime * FPS,
    },
    playing: true,
    rootId: PLAYER_COMP_ID,
    // shouldRegisterSequences: false,
    playbackRate: 1,
    imperativePlaying: false,
    setPlaybackRate: (rate) => {
      return;
      // setCurrentPlaybackRate(rate);
    },
    audioAndVideoTags: [],
  };

  if (!teams) {
    return null;
  }

  return (
    <Internals.CanUseRemotionHooksProvider>
      <Internals.Timeline.TimelineContext.Provider value={timelineContext}>
        <Internals.CompositionManager.Provider
          value={compositionManagerContext}
        >
          <Scoreboard clip={clip} />
        </Internals.CompositionManager.Provider>
      </Internals.Timeline.TimelineContext.Provider>
    </Internals.CanUseRemotionHooksProvider>
  );
};

const StatsOverlay = ({ sportingEventId }) => {
  const { data, teams } = useReport(sportingEventId, 'hockey', 5000);

  if (data === null) {
    return 'Loading...';
  }
  const { timeFn, report } = data;
  console.log('teams', teams, report);
  return <Stats teams={teams} report={report} />;

  // const { report: scoreReport, timeFn } = data;
};

export const MatchLivestreamDetail = ({
  match: {
    params: { sportingEventId, overlay },
  },
}) => {
  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    const enable = () => html.classList.add('livestreaming');
    const disable = () => html.classList.remove('livestreaming');

    enable();

    return disable;
  }, []);

  let elm = null;
  switch (overlay) {
    case 'scoreboard':
      elm = <ScoreboardOverlay sportingEventId={sportingEventId} />;
      break;
    case 'stats':
      elm = (
        <div style={{ width: '70%', margin: 'auto' }}>
          <StatsOverlay sportingEventId={sportingEventId} />
        </div>
      );
      break;
  }
  return (
    <AbsoluteFill
      onClick={() => gotoPath(`../${sportingEventId}/`)}
      className="clickable"
    >
      {elm}
    </AbsoluteFill>
  );
};
