import {
  AssignmentProvider,
  useAssignmentContext,
} from '../assignments/assignmentContext';
import React from 'react';
import { gotoRoute } from '../../route';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../lib/Page';
import { Session } from '../../../domain/Session';
import { Tooltip } from '../../../lib/Tooltip';
import _ from 'lodash';
import { Progress } from 'reactstrap';

export const CourseOverview = ({ match }) => {
  return (
    <AssignmentProvider match={match}>
      <CourseOverViewContent />
    </AssignmentProvider>
  );
};

const CourseOverViewContent = () => {
  const assignmentContext = useAssignmentContext();
  const { t } = useTranslation('module.development');
  const currentSession = Session.current();
  const resourceGroupName = currentSession?._currentResource?.name;
  const pageProps = {
    title: t('title'),
    breadcrumbs: [{ path: '/', title: 'Home' }, { title: 'cursussen' }],
    className: 'page--development development--overview',
    subtitle: () => {
      return (
        <div className={'page-title--group'}>
          <h2>
            {t('development')} {resourceGroupName}
          </h2>
          <h3>{t('developmentSubtitle')}</h3>
        </div>
      );
    },
  };
  return (
    <Page {...pageProps}>
      <div className="row learning-lines-detail">
        {assignmentContext.learningLinesWithCompetencies?.map(
          (learningLine) => (
            <LearningLineCard
              key={learningLine.id}
              learningLine={learningLine}
            />
          )
        )}
      </div>
    </Page>
  );
};

const LearningLineCard = ({ learningLine }) => {
  const assignmentContext = useAssignmentContext();
  const { t } = useTranslation('module.development');

  return (
    <div className="learning-line-card-wrapper">
      <div className="card" data-learningline-id={learningLine.learningLineId}>
        {learningLine?.image && <img src={learningLine.image} />}
        <div className="card-body card-body--title">
          <h5>{learningLine.name}</h5>
        </div>
        <div className="card-body">
          <button
            className="btn btn-primary"
            onClick={() =>
              gotoRoute('development.course-detail', {
                learningLineId: learningLine.learningLineId,
              })
            }
          >
            <i className={'i-chevron-right i-light i-xs'} />
            {t('updateDevelopment')}
          </button>
          <div className="learning-lines-detail-phases">
            {learningLine.phases.map((phase) => {
              const phaseTasks = _.compact(
                _.flatMap(phase?.practices.map((p) => p?.tasks))
              );
              const notification = assignmentContext.getActionStatus(
                phaseTasks,
                assignmentContext.assignments
              );
              return (
                <div
                  className="learning-lines-detail-phase"
                  data-phase-id={phase.phaseId}
                  key={phase.phaseId}
                >
                  <div className="learning-lines-detail-phase-title">
                    {phase.name}
                    {notification && notification !== 'empty' && (
                      <Tooltip tooltip={t(notification)}>
                        <div
                          className={`learning-lines-detail-phase-notification notification--${notification}`}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <div className="learning-lines-detail-phase-progress">
                    <div className="learning-phase-progress">
                      <Progress
                        value={assignmentContext.getCompletionStatusPercentage(
                          phaseTasks,
                          assignmentContext.assignments
                        )}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
