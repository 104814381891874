import { Page } from '../../../lib/Page';
import resourceGroupCollection from '../../../domain/ResourceGroup';
import { useEffect, useState } from 'react';
import Loading from '../../../lib/Loading';
import { AuthAwareAdapterProxy } from '../../../utils/AuthAwareAdapterProxy';
import { SubmittingButton } from '../../../lib/SubmittingButton';
import { useTranslation } from 'react-i18next';
import { InputCheckboxRadio } from '../../../lib/InputCheckBoxRadio';
import sweetAlert from '../../../lib/sweetAlert';
import teamCollection from '../../../domain/Team';

export const ShareOverview = () => {
  return (
    <Page>
      <h3>Poule overzicht</h3>
      <ShareResourceGroupsList />
    </Page>
  );
};

const ShareResourceGroupsList = () => {
  const [list, setList] = useState(null);
  const [showAll, setShowAll] = useState(false);
  useEffect(() => {
    (async () => {
      await resourceGroupCollection.fetchIfEmpty();
      setList(resourceGroupCollection);
    })();
  }, []);
  if (!list) {
    return <Loading />;
  }
  return (
    <div>
      <InputCheckboxRadio
        label={'toon alles'}
        checked={showAll}
        onChange={(v) => setShowAll(!v)}
      />
      {list
        .toJS()
        .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
        // .slice(0, 40)
        .map((rg) => (
          <ExchangeRequest resourceGroup={rg} showAll={showAll} />
        ))}
    </div>
  );
};

const ExchangeRequest = ({ resourceGroup, showAll }) => {
  const [shares, setShares] = useState(null);

  useEffect(() => {
    (async () => {
      const _rg = resourceGroupCollection.get(
        resourceGroup.attributes.resourceGroupId
      );
      const _outgoingShareRequests = await _rg.outgoingShareRequests.fetch();
      setShares(_outgoingShareRequests);
    })();
  }, []);

  if (!shares || (!showAll && shares.length === 0)) {
    return null;
  }

  return (
    <div>
      {/*{resourceGroup.attributes.name} |{' '}*/}
      {/*{resourceGroup.attributes.targetResourceName} |{' '}*/}
      {/*{shares.map((share) => share.requester)}*/}
      <ShareRequestRow resourceGroup={resourceGroup} shareRequests={shares} />
    </div>
  );
};

const ShareRequestRow = ({ resourceGroup, shareRequests }) => {
  const [teams, setTeams] = useState(null);
  const [refresh, setrefresh] = useState(0);
  useEffect(() => {
    (async () => {
      await AuthAwareAdapterProxy.withUseResourceGroup(
        resourceGroup.attributes.resourceGroupId,
        async () => {
          await teamCollection.fetch();
          setTeams(teamCollection.toJS());
        }
      );
    })();
  }, [refresh]);

  if (!teams) {
    return null;
  } else {
    return (
      <table style={{ marginTop: '2em', borderBottom: 'solid 1px #ccc' }}>
        <thead>
          <tr>
            <th colSpan={teams.length}>
              {resourceGroup.attributes.name}{' '}
              <a
                style={{ marginLeft: '2rem', cursor: 'pointer' }}
                className={'btn btn-link'}
                onClick={() => setrefresh(refresh + 1)}
              >
                Refresh
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {teams.map((team) => (
              <td style={{ padding: '2em' }}>{team.attributes.name}</td>
            ))}
          </tr>
          {shareRequests.map((s) => {
            const _share = resourceGroupCollection
              .get(resourceGroup.attributes.resourceGroupId)
              .outgoingShareRequests.get(s.shareRequestId);

            return (
              <tr>
                <td style={{ padding: '2em' }} colSpan={teams.length}>
                  <ShareRequest
                    shareRequest={_share}
                    poule={resourceGroup.attributes.name}
                    teams={teams}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
};

const ShareRequest = ({ shareRequest, poule, teams }) => {
  const [state, setState] = useState(null);
  const { t } = useTranslation();

  const _team = teams.find(
    (team) => team.attributes.teamId === shareRequest.shareAttributes.teamId
  );

  const acceptRequest = async () => {
    await shareRequest.accept();
    setState('accepted');
  };

  const denyRequest = async () => {
    await shareRequest.reject();
    setState('denied');
  };

  return (
    <div className={'d-flex'}>
      <div style={{ paddingRight: '1em' }}>
        {state === 'accepted' ? '✅' : state === 'denied' ? '❌' : ''}
        {shareRequest.requester}
        <span style={{ margin: '0 1em' }}>
          {_team?.attributes?.name ?? 'not-found'}
        </span>
      </div>
      {!state && (
        <div style={{ marginLeft: 'auto' }}>
          <SubmittingButton
            className={'btn btn-primary'}
            onClick={acceptRequest}
          >
            Accept
          </SubmittingButton>
          <SubmittingButton
            style={{ marginLeft: '1em' }}
            className={'btn btn-secondary'}
            onClick={denyRequest}
          >
            Decline
          </SubmittingButton>
        </div>
      )}
    </div>
  );
};
