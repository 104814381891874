import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { asyncAction } from 'mobx-utils';

import { Session } from 'domain/Session';
import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Page } from 'lib/Page';

import { LearningLine } from '../components/learning-line';
import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';
import { DevelopmentPlanQueryService } from 'domain/Development/DevelopmentPlanQueryService';
import { withTranslation } from 'react-i18next';

import { Error } from 'lib/PlaceHolder';
import { gotoRoute } from '../../route';
import { AssignmentProvider } from '../assignments/assignmentContext';

const TDPDetailPage = withTranslation('module.development')(
  observer(
    class TDPDetailPage extends Component {
      constructor(props) {
        super(props);
        this.dataState = observable('loading');
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');
          const currentSession = Session.current();

          yield currentSession.isReady();
          this.resourceGroupName = currentSession?._currentResource?.name;
          this.learningLines = yield LearningLineQueryService.learningLines({
            includeCustom: window.isSkillReflect, // todo: False for KNKV but a more pretty check.
            refresh: true,
            competencies: !window.isSkillReflect,
          });

          if (this.learningLines.length === 1) {
            // Skip development page when there is only 1 learningLine available.
            gotoRoute('development.tdp-update', {
              learningLineId: this.learningLines[0].id,
            });
          }

          this.developmentPlan =
            yield DevelopmentPlanQueryService.developmentPlanByResourceGroup();

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load team detail',
          });
          console.log(e);
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      handleSave(args) {
        console.debug('TODO implement handleSave. args:', args);
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { title: this.props.t('development') },
          ],
          subtitle: () => {
            return (
              <div className={'page-title--group'}>
                <h2>
                  {this.props.t('development')} {this.resourceGroupName}
                </h2>
                <h3>{this.props.t('developmentSubtitle')}</h3>
              </div>
            );
          },
          // primaryAction: {
          //   title: 'Kies ontwikkeldoelen' /*TODO:TRANSLATE*/,
          //   onClick: () => gotoPath('create-growth-ambition'),
          // },
          // filters: [
          //   {
          //     title: 'Overzicht' /*TODO:TRANSLATE*/,
          //     path: 'learning-line-overview',
          //     isActive: true,
          //   },
          //   // {
          //   //   title: 'Huidige doelen' /*TODO:TRANSLATE*/,
          //   //   path: 'monitor-growth-ambition',
          //   // },
          // ],
          className: 'page--development development--overview',
        };

        return (
          <Page {...pageProps}>
            <div className="row learning-lines-detail">
              <AssignmentProvider>
                {this.learningLines.map((learningLine) => (
                  <div
                    className="learning-line-card-wrapper"
                    key={learningLine.id}
                  >
                    <LearningLine
                      learningLine={learningLine}
                      developmentPlan={this.developmentPlan}
                    ></LearningLine>
                  </div>
                ))}
              </AssignmentProvider>
            </div>
          </Page>
        );
      }
    }
  )
);

export { TDPDetailPage };
