import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import LoginPage from './pages/LoginPage';
import RegisterPage from './sessionlessPages/RegisterPage';
import * as mobx from 'mobx';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Session } from './domain/Session';

import Authentication from './utils/Authentication';
import { MainRoute } from './modules/index';

import Loading from './lib/Loading';
import { UserNavigation } from './modules/navigation';
import { AppNav } from 'modules/apps/nav';
import { withTranslation } from 'react-i18next';
import { TourComponent } from './modules/tour';
import { NavigateToResourceGroupLanding } from 'modules/navigation/resource-group-landing';
import { Helmet } from 'react-helmet';
import {
  BannerNotificationContainer,
  MenuNotificationContainer,
  NotificationCacheInvalidator,
  PopupNotificationContainer,
} from 'lib/notifications';

import * as Sentry from '@sentry/react';
import PasswordResetPage from './sessionlessPages/PasswordResetPage';
import VerifyEmailPage from './sessionlessPages/VerifyEmailPage';
import { RenderIfNewUser } from './lib/RenderIfNewUser';
import { initTagManager } from './utils/ga4';

import { MainPageErrorFallback } from './lib/PlaceHolder';
import AccountSetup from './sessionlessPages/AccountSetup';

mobx.useStrict(true);

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    Authentication.handleAuthentication(location);
    // setTimeout(Authentication.handleAuthentication.bind(Authentication), 1);
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // NOTE: switching between devtools & main window triggers focus
      refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
    },
  },
});

const Navigation = withRouter(
  withTranslation('menu')(
    observer(
      class Navigation extends Component {
        get apps() {
          const session = Session.current();
          return session.getAppManager().getListedApps();
        }

        switchToApp(app) {
          const session = Session.current();
          return session.getAppManager().switchToApp(app);
        }

        render() {
          if (Authentication.isEmbed()) {
            return null;
          }

          const session = Session.current();

          const activeApp = session
            .getAppManager()
            .getActiveAppByPath(this.props.location.pathname);

          // handle cases where activeApp or activeApp.color are not (yet) set
          const appHeaderStyle = {
            backgroundColor: (activeApp && activeApp.color) || undefined,
          };
          const currentUser = session.user;

          if (!currentUser.isReady || !activeApp) {
            return null;
          } else {
            return (
              <>
                <RenderIfNewUser>
                  {/* Used for tag manager */}
                  <div
                    style={{
                      position: 'absolute',
                      width: '2px',
                      height: '2px',
                    }}
                    id="new-user"
                  />
                </RenderIfNewUser>
                <NotificationCacheInvalidator session={session} />
                <BannerNotificationContainer session={session} />
                <PopupNotificationContainer session={session} />
                <div id="aside"></div>
                <div id="main-content">
                  <div className="app-header" style={appHeaderStyle}>
                    <div className="app-header-content">
                      <div className="app-header-branding">
                        <AppNav
                          apps={this.apps}
                          activeApp={activeApp}
                          onChange={this.switchToApp}
                        />
                      </div>
                      <div id="app-header-center" />
                      <MenuNotificationContainer />
                      <UserNavigation
                        session={session}
                        currentUser={currentUser}
                        app={activeApp}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ zIndex: 9999, position: 'fixed' }}
                  id={'menuPortalTarget'}
                ></div>
              </>
            );
          }
        }
      }
    )
  )
);

const MainPage = ({ user }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Navigation key="navigation" />
      <Sentry.ErrorBoundary
        showDialog
        beforeCapture={(scope) => {
          scope.setTag('location', 'main');
        }}
        fallback={<MainPageErrorFallback />}
        key="main"
      >
        <MainRoute />
      </Sentry.ErrorBoundary>
      <TourComponent key="tour" />
      <Helmet key="helmet">
        {window.isSkillReflect
          ? [
              <title key="title">SkillReflect</title>,
              <link
                key="icon1"
                rel="apple-touch-icon"
                href="/favicon_sr.png"
              />,
              <link
                key="icon2"
                rel="icon"
                sizes="160x160"
                href="/favicon_sr.png"
              />,
            ]
          : [
              <title key="title">TeamTV</title>,
              <link
                key="icon1"
                rel="apple-touch-icon"
                href="/favicon.ico?v=2"
              />,
              <link
                key="icon2"
                rel="icon"
                sizes="160x160"
                href="/favicon.ico?v=2"
              />,
            ]}
      </Helmet>
    </QueryClientProvider>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    const session = Session.current();
    if (window.location.search.indexOf('resource-group-id') !== -1) {
      const loc = window.location.search.indexOf('resource-group-id');
      const resourceGroupId = window.location.search.substr(loc + 18, 36);
      session.setResourceGroupId(
        resourceGroupId,
        window.location.href.substr(0, window.location.href.indexOf('?'))
      );
    }
    this.user = session.user;
    initTagManager(props.history);
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/login"
          render={() =>
            Authentication.isAuthenticated === true ? (
              <Redirect to="/" />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route
          path="/callback"
          render={(props) => {
            handleAuthentication(props);
            setTimeout(() => {
              this.user.logAction('LoggedIn');
            }, 1000);
            return <Loading type="fullscreen" />;
          }}
        />
        <Route
          exact
          path="/"
          render={(props) =>
            Authentication.isAuthenticated === true ? (
              <NavigateToResourceGroupLanding />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route
          exact
          path="/register"
          render={(props) =>
            Authentication.isAuthenticated === true ? (
              <NavigateToResourceGroupLanding />
            ) : (
              <RegisterPage props={props} />
            )
          }
        />
        <Route
          exact
          path="/account-setup"
          render={(props) =>
            Authentication.isAuthenticated === true ? (
              <NavigateToResourceGroupLanding />
            ) : (
              <AccountSetup props={props} />
            )
          }
        />
        <Route
          exact
          path="/passwordreset"
          render={(props) =>
            Authentication.isAuthenticated === true ? (
              <NavigateToResourceGroupLanding />
            ) : (
              <PasswordResetPage />
            )
          }
        />
        <Route
          path="/email-verified"
          render={(props) =>
            Authentication.isAuthenticated === true ? (
              <MainPage user={this.user} />
            ) : (
              <VerifyEmailPage props={props} />
            )
          }
        />
        <Route exact path={'/test-landing'} render={() => <div>hi</div>} />
        <MainPage user={this.user} />
      </Switch>
    );
  }
}
Element.prototype._addEventListener = Element.prototype.addEventListener;
Element.prototype.addEventListener = function (a, b, c) {
  if (a === 'keydown' && this.classList.contains('jwplayer')) return;
  this._addEventListener(a, b, c || false);
};

export default withRouter(observer(App));
