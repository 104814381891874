import React, { Component, Fragment, useState, useEffect } from 'react';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { withTranslation } from 'react-i18next';
import { getMatchConfigurationInput } from './MatchConfigurationInput';
import { Modal } from 'lib/Modal';
import CheckboxScheduling from './MatchConfigurationInput/CheckboxScheduling';
import Loading from '../../../lib/Loading';
import moment, { isMoment } from 'moment';
import sportingEventCollection from 'domain/SportingEvent';

const initialValues = {
  homeTeam: '',
  awayTeam: '',
  scheduledAt: undefined,
  matchConfig: {},
  automaticCameraConfig: {},
};

const ACLInput = ({ t, aclKey, setAclKey }) => {
  if (!window.isSkillReflect) {
    return null;
  }

  const shareTrainerObservations =
    aclKey === 'allow-all' || aclKey === 'view-trainer';
  const sharePeerObservations =
    aclKey === 'allow-all' || aclKey === 'view-peer';

  const setShareTrainerObservations = (checked) => {
    if (checked) {
      if (sharePeerObservations) {
        setAclKey('allow-all');
      } else {
        setAclKey('view-trainer');
      }
    } else {
      if (sharePeerObservations) {
        setAclKey('view-peer');
      } else {
        setAclKey('view-own');
      }
    }
  };

  const setSharePeerObservations = (checked) => {
    if (checked) {
      if (shareTrainerObservations) {
        setAclKey('allow-all');
      } else {
        setAclKey('view-peer');
      }
    } else {
      if (shareTrainerObservations) {
        setAclKey('view-trainer');
      } else {
        setAclKey('view-own');
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <p>{t('ACLDescription')}</p>
      </div>
      <div className="col-12">
        <input
          type="checkbox"
          checked={shareTrainerObservations}
          onClick={(e) => setShareTrainerObservations(e.target.checked)}
        />{' '}
        {t('shareTrainerObservations')}
      </div>
      <div className="col-12">
        <input
          type="checkbox"
          checked={sharePeerObservations}
          onClick={(e) => setSharePeerObservations(e.target.checked)}
        />{' '}
        {t('sharePeerObservations')}
      </div>
    </div>
  );
};

const EditMatchModalContent = withTranslation('module.match.edit')((props) => {
  let { onReady, sportingEvent, sportType, t } = props;

  const {
    Component: MatchConfigurationInput,
    getInitialValues: getMatchConfigurationInitialValues,
    getValues: getMatchConfigurationValues,
    validator: matchConfigurationValidator,
  } = getMatchConfigurationInput(sportType, sportingEvent);

  const handleSubmit = async (values, formikBag) => {
    const matchConfig = getMatchConfigurationValues(values.matchConfig);
    const automaticCameraConfig = values.automaticCameraConfig;
    // onSave({
    //   // homeTeam: values.homeTeam.value,
    //   // awayTeam: values.awayTeam.value,
    //   // scheduledAt: values.scheduledAt,
    //   matchConfig,
    // });
    const sportingEventId = await sportingEvent.getPrivilegedToSportingEventId(
      'edit'
    );
    let changed = false;
    if (sportingEventId !== sportingEvent.sportingEventId) {
      sportingEvent = await sportingEventCollection.getOrFetch(sportingEventId);
    }
    if (
      automaticCameraConfig &&
      Object.keys(automaticCameraConfig).length > 0 &&
      automaticCameraConfig !== sportingEvent.automaticCameraConfig
    ) {
      await sportingEvent.setAutomaticCameraConfig(
        automaticCameraConfig,
        false
      );
      changed = true;
    }
    if (Object.keys(matchConfig).length > 0) {
      await sportingEvent.setMatchConfig(matchConfig, false);
      changed = true;
    }

    if (
      Math.abs(values.scheduledAt - new Date(sportingEvent.scheduledAt)) > 1
    ) {
      await sportingEvent.reschedule(values.scheduledAt, false);
    }
    if (changed) {
      await sportingEvent.fetch();
    }
    onReady();
  };

  const validate = (values) => {
    const errors = {};

    const matchConfigErrors = matchConfigurationValidator(values.matchConfig);
    for (const k of Object.keys(matchConfigErrors)) {
      errors[`matchConfig_${k}`] = matchConfigErrors[k];
    }

    if (
      values?.automaticCameraConfig?.record &&
      values?.automaticCameraConfig?.fieldId === 'unset'
    ) {
      errors.fieldId = true;
    }

    return errors;
  };

  initialValues.matchConfig = {
    getMatchConfigurationInitialValues,
    ...sportingEvent.tags.matchConfig,
  };
  initialValues.automaticCameraConfig =
    sportingEvent.tags.automaticCameraConfig;
  initialValues.scheduledAt = moment(sportingEvent.scheduledAt);

  return (
    <Modal onCloseClick={onReady}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({
          handleSubmit,
          values,
          submitCount,
          isSubmitting,
          errors,
          setFieldValue,
          onReset,
        }) => {
          // const teamOptions = TeamCollection.uniqueArray().map(team => ({
          //   value: team.id,
          //   label: team.label,
          // }));
          //
          // teamOptions.sort((a, b) => {
          //   const labelA = a.label;
          //   const labelB = b.label;
          //   return (labelA > labelB) - (labelA < labelB);
          // });

          const validClassName = (key) => {
            if (!submitCount) {
              return '';
            }

            return errors[key] ? 'is-invalid' : 'is-valid';
          };
          return (
            <form
              onSubmit={
                !isSubmitting ? handleSubmit : (e) => e.preventDefault()
              }
            >
              <div className="modal-header">
                <h5 className="modal-title">{t('title')}</h5>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('scheduledAt')}`}
                    >
                      <label htmlFor="new-match-scheduled-at">
                        {t('module.match.new:scheduledAt')}
                      </label>
                      <DatePicker
                        id="new-match-scheduled-at"
                        name="scheduledAt"
                        className="form-control-datepicker"
                        selected={values.scheduledAt}
                        onChange={(value) => {
                          value &&
                            isMoment(value) &&
                            setFieldValue(
                              'scheduledAt',
                              value.startOf('minute')
                            );
                        }}
                        // value={values.scheduledAt}
                        showTimeSelect
                        autoComplete="off"
                        dateFormat="LLL"
                        timeFormat="HH:mm"
                        timeCaption="Tijd"
                        isClearable={false}
                        timeIntervals={5}
                        placeholderText={t(
                          'module.match.new:scheduledAtPlaceholder'
                        )}
                        // popperPlacement="right"
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                </div>
                {MatchConfigurationInput && (
                  <div className="row">
                    <div className="col-12">
                      <p>{t('description')}</p>
                    </div>

                    <MatchConfigurationInput
                      submitCount={submitCount}
                      errors={errors.matchConfig}
                      values={values.matchConfig}
                      setFieldValue={(field, value) =>
                        setFieldValue(`matchConfig.${field}`, value)
                      }
                      validClassName={(field) =>
                        validClassName(`matchConfig_${field}`)
                      }
                    />
                  </div>
                )}
                {!window.isSkillReflect && ( // Todo: Only show if would be available. (date in future / no video yet etc.)
                  <CheckboxScheduling
                    callback={(value) =>
                      setFieldValue('automaticCameraConfig', value)
                    }
                    errors={errors}
                    validClassName={(k) => validClassName(k)}
                    values={values}
                  />
                )}
              </div>
              <div className="modal-footer">
                <div className="form-actions">
                  <button type="submit" className="btn btn-primary">
                    {t('common:save')}
                    {isSubmitting && (
                      <Loading size={16} color={'#fff'} type={'spinner'} />
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={onReady}
                  >
                    {t('common:cancel')}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
});

const ShareTagsModalContent = withTranslation('module.match.edit')((props) => {
  const { onReady, sportingEvent, sportType, t } = props;
  const [observationLogACL, setObservationLogACL] = useState(null);

  useEffect(() => {
    (async () => {
      const observationLogACL = await sportingEvent.getObservationLogACL();
      setObservationLogACL(observationLogACL);
    })();
  }, [sportingEvent]);

  if (observationLogACL === null) {
    return null;
  }

  const { getInitialValues: getMatchConfigurationInitialValues } =
    getMatchConfigurationInput(sportType);

  const handleSubmit = async (values, formikBag) => {
    if (values.aclKey !== observationLogACL.key) {
      await sportingEvent.setObservationLogACL(values.aclKey);
    }
    onReady();
  };

  const validate = (values) => {
    const errors = {};
  };

  initialValues.aclKey = observationLogACL.key;

  return (
    <Modal onCloseClick={onReady}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({
          handleSubmit,
          values,
          submitCount,
          isSubmitting,
          errors,
          setFieldValue,
          onReset,
        }) => {
          const validClassName = (key) => {
            if (!submitCount) {
              return '';
            }

            return errors[key] ? 'is-invalid' : 'is-valid';
          };
          return (
            <form
              onSubmit={
                !isSubmitting ? handleSubmit : (e) => e.preventDefault()
              }
            >
              <div className="modal-header">
                <h5 className="modal-title">{t('shareTags')}</h5>
              </div>
              <div className="modal-body">
                <ACLInput
                  t={t}
                  aclKey={values.aclKey}
                  setAclKey={(aclKey) => setFieldValue('aclKey', aclKey)}
                />
              </div>
              <div className="modal-footer">
                <div className="form-actions">
                  <button type="submit" className="btn btn-primary">
                    {t('common:save')}
                    {isSubmitting && (
                      <Loading size={16} color={'#fff'} type={'spinner'} />
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={onReady}
                  >
                    {t('common:cancel')}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
});

export { EditMatchModalContent as EditMatchInput, ShareTagsModalContent };
