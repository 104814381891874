//
//
import React, { Component, Fragment } from 'react';

import ReactDOM from 'react-dom/client';
import './isSkillReflect';

import './polyfill';

// import ons from 'onsenui';

import './lib/styles/index.scss';
import './css/style.css';
import './css/custom.css';

import App from './App';

import history from './utils/History';
import './utils/ApiClient';
import Authentication from './utils/Authentication';
import { Router } from 'react-router-dom';
// import GoogleAnalytics from "react-ga";
import TagManager from 'react-gtm-module';

import registerServiceWorker, { unregister } from './registerServiceWorker';
import { Notification } from './lib/Notification';
import { RemoteCommandDispatcherStateIndicator } from './infra/RemoteCommandDispatcher';

import * as Sentry from '@sentry/react';

import './lib/i18n';
import { shouldIgnoreError } from './sentry-filter-errors-by-breadcrumbs';

unregister();

const isRunningTests = (() => {
  const isTestLanding = window.location.href.indexOf('test-landing') !== -1;
  if (isTestLanding) {
    window.localStorage.setItem('isRunningTests', '1');
  }
  return window.localStorage.getItem('isRunningTests') === '1';
})();

if (isRunningTests) {
  console.warn('!! RUNNING TESTS !! External tools disabled !!');
}

if (
  process.env.REACT_APP_SENTRY_DEBUG ||
  (process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'test' &&
    !isRunningTests)
) {
  Sentry.init({
    dsn: 'https://7431f08c619e48fb8dd2d52fcdea7743@sentry.io/1219642',
    release: process.env.REACT_APP_BUILD_VERSION,
    environment: process.env.NODE_ENV,
    ignoreErrors: ['NetworkError', 'Network Error'],
    integrations: [
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      shouldIgnoreError(event, hint);
      // return event;
    },
  });
}

Authentication.init();

//
// class RootPage extends React.Component {
// 	renderPage(route, navigator) {
// 		const props = route.props || {};
// 		props.navigator = navigator;
//
// 		return React.createElement(route.component, props);
// 	}
//
// 	render() {
// 		return (
// 			<Navigator
// 			initialRoute={{component: LoginPage}}
// 			renderPage={this.renderPage}
// 			animation="slide"
// 			/>
// 		);
// 	}
// }

document.documentElement.classList.add(
  window.isSkillReflect ? 'skillreflect' : 'teamtv'
);

/* Set Viewport heights without taskbars and scrollbars */
let innerVH, innerVW;

innerVH = document.documentElement.clientHeight;
innerVW = document.documentElement.clientWidth;

document.documentElement.style.setProperty('--innerVW', innerVW + 'px');
document.documentElement.style.setProperty('--innerVH', innerVH + 'px');

window.addEventListener('resize', function (e) {
  innerVH = window.innerHeight;
  innerVW = window.innerWidth;
  document.documentElement.style.setProperty('--innerVW', innerVW + 'px');
  document.documentElement.style.setProperty('--innerVH', innerVH + 'px');
});

const Initialized = () => {
  document.getElementById('app').classList.add('initialized');
  // Remove loaded splash screen.
  setTimeout(() => {
    document.querySelector('.splash-screen').remove();
  }, 500);
};

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <>
    <Router history={history}>
      <Fragment>
        <Notification />
        <RemoteCommandDispatcherStateIndicator />
        <App />
      </Fragment>
    </Router>
    <Initialized />
  </>
);
