import React, { useEffect, useRef, useState } from 'react';
import phaseCompletedLottie from 'lib/animations/lottie/phase_completed.json';
import Lottie from 'react-lottie';

export const PhaseCompletedLottie = ({ width = 35 }) => {
  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: phaseCompletedLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const [activeSegment, setActiveSegment] = useState([0, 75]);
  const lottieRef = useRef();

  const loop = () => {
    if (lottieRef.current?.anim?.isPaused) {
      setTimeout(() => {
        let frames = [40, 75];
        setActiveSegment(frames);
        if (lottieRef?.current) {
          lottieRef?.current.play();
          loop();
        }
      }, 4000);
    } else {
      // Make sure there is no inaccurracy in mounting to play times in animation.
      if (lottieRef?.current) {
        setTimeout(() => {
          loop();
        }, 4000);
      }
    }
  };

  useEffect(() => {
    loop();
  }, []);

  return (
    <Lottie
      ref={lottieRef}
      style={{ width: width }}
      options={lottieOptions}
      segments={activeSegment}
      isPaused={false}
    />
  );
};
