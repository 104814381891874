import { Route, RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { getRoutePath } from 'modules/route';
import { Session } from 'domain/Session';

import { PDPOverviewPage } from './containers/personal-overview';
import { PDPDetailPage } from './containers/personal-detail';
import { PDPUpdatePage } from './containers/personal-plan';

import { PDPTablePage } from './containers/personal-plan-table';

import { TDPDetailPage } from './containers/team-detail';
import { TDPUpdatePage } from './containers/team-plan';

import {
  GrowthAmbitionCreate,
  GrowthAmbitionMonitor,
  GrowthAmbitionEvaluate,
} from './growth-ambition';

import { LearningGroupView } from './learningGroup/learningGroupView';
import { LearningGroupDetail } from './learningGroup/learningGroupDetail';

import PersonCollection from 'domain/Person';

import { DevelopmentPlanQueryService } from 'domain/Development/DevelopmentPlanQueryService';
import { injectLoader } from 'lib/InjectLoader';
import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';

import { joinResourceGroup, getLearningGroup } from './learningGroup/utils';

import './rating.scss';
import { CourseOverview } from './courses/CourseOverview';
import { CourseDetail } from './courses/CourseDetail';

const MODULE_NAME = 'development';

const personDevelopmentLoader = async (props) => {
  const currentSession = Session.current();
  await currentSession.isReady();
  const personId = props.match.params.personId;
  const person = await PersonCollection.getOrFetch(personId);
  const developmentPlan =
    await DevelopmentPlanQueryService.developmentPlanByPerson(personId);
  const learningLines = await LearningLineQueryService.learningLinesByPerson(
    personId
  );
  return {
    subtitle: person.fullName,
    person,
    developmentPlan,
    learningLines,
  };
};

const teamDevelopmentLoader = async (props) => {
  const currentSession = Session.current();
  await currentSession.isReady();
  const teamId = currentSession.currentObjectId();
  const developmentPlan =
    await DevelopmentPlanQueryService.developmentPlanByResourceGroup();

  const learningLines = await LearningLineQueryService.learningLinesByTeam(
    teamId
  );
  return {
    team: true,
    developmentPlan,
    learningLines,
  };
};

export default new Module(
  MODULE_NAME,
  [],
  [
    new RouteRedirect('', async () => {
      const currentSession = Session.current();
      await currentSession.isReady();
      if (currentSession.isTrainer()) {
        return getRoutePath(`${MODULE_NAME}.pdp-overview`, { subject: 'team' });
      } else {
        return getRoutePath(`${MODULE_NAME}.pdp-detail`, {
          personId: currentSession.currentPersonId(),
        });
      }
    }),
    new RouteRedirect('pdp', async () => {
      const currentSession = Session.current();
      await currentSession.isReady();
      if (currentSession.isTrainer()) {
        return getRoutePath(`${MODULE_NAME}.pdp-overview`);
      } else {
        return getRoutePath(`${MODULE_NAME}.pdp-start`, {
          personId: currentSession.currentPersonId(),
        });
      }
    }),
    new RouteExact('pdp/overview', PDPOverviewPage, 'pdp-overview'),
    new RouteExact('pdp/table', PDPTablePage, 'pdp-table'),
    new RouteRedirect(
      'pdp/:personId/start',
      async ({ personId }) => {
        const developmentPlan =
          await DevelopmentPlanQueryService.developmentPlanByPerson(personId);
        // if (!developmentPlan.isNew) {
        //   await developmentPlan.growthAmbitions.fetchIfEmpty();
        // }

        if (developmentPlan.getActiveGrowthAmbition() !== null) {
          return getRoutePath(`${MODULE_NAME}.pdp-monitor-growth-ambition`, {
            personId,
          });
        } else {
          return getRoutePath(`${MODULE_NAME}.pdp-learning-line-overview`, {
            personId,
          });
        }
      },
      'pdp-start'
    ),
    new RouteExact(
      'pdp/:personId/learning-line-overview',
      PDPDetailPage,
      'pdp-learning-line-overview'
    ),
    new RouteExact(
      'pdp/:personId/learningline-detail/:learningLineId',
      PDPUpdatePage,
      'pdp-update'
    ),
    new Route(
      'pdp/:personId/create-growth-ambition',
      injectLoader(personDevelopmentLoader, GrowthAmbitionCreate),
      'pdp-create-growth-ambition'
    ),
    new Route(
      'pdp/:personId/monitor-growth-ambition',
      injectLoader(personDevelopmentLoader, GrowthAmbitionMonitor),
      'pdp-monitor-growth-ambition'
    ),
    new Route(
      'pdp/:personId/evaluate-growth-ambition',
      injectLoader(personDevelopmentLoader, GrowthAmbitionEvaluate),
      'pdp-evaluate-growth-ambition'
    ),

    new RouteRedirect(
      'tdp',
      async () => {
        const currentSession = Session.current();
        await currentSession.isReady();

        return getRoutePath(`${MODULE_NAME}.tdp-detail`);
      },
      'tdp-start'
    ),
    new RouteRedirect('tdp/learning-line-overview', 'tdp/detail'),
    new RouteExact('tdp/detail', TDPDetailPage, 'tdp-detail'),
    new RouteExact(
      'tdp/update/:learningLineId/:taskId?',
      TDPUpdatePage,
      'tdp-update'
    ),

    // new RouteRedirect('courses', 'tdp/detail'),
    new RouteExact('courses', CourseOverview, 'courses'),
    new RouteExact(
      'courses/:learningLineId/:taskId?',
      CourseDetail,
      'course-detail'
    ),

    new Route(
      'tdp/create-growth-ambition',
      injectLoader(teamDevelopmentLoader, GrowthAmbitionCreate),
      'tdp-create-growth-ambition'
    ),
    new Route(
      'tdp/monitor-growth-ambition',
      injectLoader(teamDevelopmentLoader, GrowthAmbitionMonitor),
      'tdp-monitor-growth-ambition'
    ),
    new Route(
      'tdp/evaluate-growth-ambition',
      injectLoader(teamDevelopmentLoader, GrowthAmbitionEvaluate),
      'tdp-evaluate-growth-ambition'
    ),
    new RouteExact('learningGroups', LearningGroupView, 'learningroups'),
    new RouteRedirect(
      'learningGroups/current/join/:traineeResourceGroupId',
      async ({ traineeResourceGroupId }) => {
        const currentSession = Session.current();
        await currentSession.isReady();

        const learningGroupId = currentSession.currentObjectId();
        const learningGroup = await getLearningGroup(learningGroupId);
        for (const trainee of learningGroup.trainees) {
          if (trainee.resourceGroupId === traineeResourceGroupId) {
            // The traineeResourceGroupId actually exists.
            if (!currentSession.isMemberOf(traineeResourceGroupId)) {
              await joinResourceGroup(
                traineeResourceGroupId,
                currentSession.currentResourceGroup().id
              );
            }

            currentSession.setResourceGroupId(
              trainee.resourceGroupId,
              getRoutePath('development.tdp')
            );
            // Prevent a redirect as it only takes time.
            return '#';
          }
        }
        // When not found, just redirect to root
        return '/';
      },
      'learning-group-join-trainee'
    ),

    new RouteExact(
      'learningGroups/:learningGroupId',
      LearningGroupDetail,
      'learninggroupdetail'
    ),
  ]
);
