import { Page } from 'lib/Page';
import { useQuery, useQueryClient } from 'react-query';
import api from 'utils/ApiClient';
import Loading from 'lib/Loading';
import React, { useState } from 'react';
import EducationPlaceholder from 'img/icons/education_placeholder.svg';
import './index.scss';
import { gotoRoute } from '../../route';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { NoResults } from 'lib/PlaceHolder';
import { AddCourseModal } from './Components/addCourseModal';
import { Session } from '../../../domain/Session';

export const LearningGroupView = () => {
  const { t } = useTranslation('module.development.learningGroups');
  const [filter, setFilter] = useState(null);
  const [search, setSearch] = useState('');
  const [addCourseOpened, setAddCourseOpened] = useState(false);
  const queryClient = useQueryClient();
  const learningGroups = useQuery('learningGroups', () => getLearningGroups(), {
    placeholderData: [],
  });

  const getLearningGroups = async () => {
    let { data } = await api.get('learningGroups');
    return data ? data : [];
  };
  const currentSession = Session.current();

  if (learningGroups.isFetched) {
    const pageProps = {
      title: t('menu:courses'),
      breadcrumbs: [{ path: '/', title: 'Home' }, { title: t('menu:courses') }],
      primaryAction: {
        title: t('addCourse'),
        onClick: () => {
          setAddCourseOpened(true);
        },
        disabled: !currentSession
          .currentPrivileges()
          .hasPrivilege('LearningGroup:create'),
      },
      viewFilters: [
        {
          type: 'search',
          onChange: (v) => debounce(setSearch(v), 200),
          placeholder: t('common:search'),
        },
        {
          type: 'buttonGroup',
          buttons: [
            {
              label: t(`common:all`),
              onClick: () => setFilter(null),
              isActive: !filter,
            },
            {
              label: t(`courseState.new`),
              onClick: () => setFilter('new'),
              isActive: filter === 'new',
            },
            {
              label: t(`courseState.provisioned`),
              onClick: () => setFilter('provisioned'),
              isActive: filter === 'provisioned',
            },
            {
              label: t(`courseState.started`),
              onClick: () => setFilter('started'),
              isActive: filter === 'started',
            },
          ],
        },
      ],
    };

    return (
      <Page {...pageProps}>
        <View
          learningGroups={learningGroups.data}
          search={search}
          filter={filter}
        />
        {learningGroups.data.length === 0 && <NoResults />}
        <AddCourseModal
          isOpen={addCourseOpened}
          onClose={() => setAddCourseOpened(false)}
          callback={() => {
            queryClient.invalidateQueries('learningGroups');
            setAddCourseOpened(false);
          }}
        />
      </Page>
    );
  } else {
    return <Loading type={'fullscreen'} />;
  }
};
const View = ({ learningGroups, search, filter }) => {
  return (
    <div>
      {learningGroups
        .filter((l) => {
          if (filter && l.state !== filter) {
            return false;
          }
          return l.name.toLowerCase().includes(search.toLowerCase());
        })
        .sort((a, b) => b.updated.localeCompare(a.updated)) // newest first
        .map((learningGroup) => (
          <LearningGroupLine
            key={learningGroup.learningGroupId}
            learningGroup={learningGroup}
          ></LearningGroupLine>
        ))}
    </div>
  );
};

const LearningGroupLine = ({ learningGroup }) => {
  const { t } = useTranslation('module.development.learningGroups');
  return (
    <div
      key={learningGroup.learningGroupId}
      className={`overview-item overview-item--learningGroup overview-item-with-icon user-setting-item learningGroup--${learningGroup.state}`}
    >
      <div className="overview-item-icon user-setting-thumb">
        <div
          className="user-setting-thumb-inner"
          style={{
            backgroundImage: `url('${EducationPlaceholder}')`,
          }}
        ></div>
      </div>
      <div className="flex-content w-100 justify-content-end">
        <div className="user-setting-user-name" style={{ marginRight: 'auto' }}>
          <b>{learningGroup.name}</b>
        </div>
        <div style={{ marginRight: '30px' }}>
          {t(`courseState.${learningGroup.state}`)}
        </div>
        <div style={{ marginRight: '30px' }}>
          {learningGroup.trainees.length} {t('trainees')}
        </div>
        <div className="user-setting-icons">
          <div
            className="user-setting-icon view clickable"
            onClick={() =>
              gotoRoute('development.learninggroupdetail', {
                learningGroupId: learningGroup.learningGroupId,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
