import React, { useEffect, useState } from 'react';

import { useAssignmentContext } from '../assignments/assignmentContext';
export const ReadingTask = ({ task }) => {
  const assignmentContext = useAssignmentContext();
  const isTrainee = assignmentContext.isTrainee;

  // dependent query, make sure we have most recent data when invalidating or setting first time assignment.
  const assignment = assignmentContext.getAssignmentByTaskID(task.taskId);

  const practice = assignmentContext.getPracticeByPracticeId(task.practiceId);

  const markAsRead = async () => {
    if (isTrainee) {
      const data = {
        submissionTRN: task.taskTRN,
        taskTRN: task.taskTRN,
        name: `${practice.name} - ${task.name}`,
      };
      assignmentContext.addVersion(data, assignment);
    }
  };

  useEffect(() => {
    if (!assignment) {
      markAsRead(); // Create version on load.
    }
  }, [assignment]);

  return null;
};

export const ReadingTaskVersion = ({ assignment }) => {
  const assignmentContext = useAssignmentContext();
  const isTrainee = assignmentContext.isTrainee;

  if (isTrainee) {
    return null;
  }
  return (
    <div
      className={`readingtask ${
        assignment?.state === 'accepted' || assignment?.state === 'submitted'
          ? 'readingtask--isread'
          : ''
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
        <circle cx="12" cy="12" r="3"></circle>
      </svg>
      <span>
        {assignment?.state === 'accepted' || assignment?.state === 'submitted'
          ? 'Is read by trainee'
          : 'Not yet opened by trainee'}
      </span>
    </div>
  );
};
