import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import {
  get as getObservations,
  videoId as observationVideoId,
} from 'domain/Observation';
import { LearningPhase } from './learning-phase';
import { LearningLineFilter } from './learning-line-filter';
import sportingEventCollection from 'domain/SportingEvent/SportingEvent';

import './index.scss';
import { UnreadMessagesInLearningLine } from './learning-line-unread';
import { DaysAgo } from '../../../lib/DaysAgo';
import { useTranslation } from 'react-i18next';
import { Session } from 'domain/Session';
import { AssignmentProvider } from '../assignments/assignmentContext';
import { TaskModal } from '../assignments/TaskModal';
import { AssignmentDetail } from '../assignments/AssignmentDetail';

const LearningLineDetailsFC = ({ learningLine, developmentPlan, match }) => {
  const [filter, setFilter] = useState({
    videoIds: [],
    roles: [],
  });

  const { t } = useTranslation('module.development');

  const fetchObservations = () =>
    getObservations(
      `?developmentPlanId=${developmentPlan.get('developmentPlanId')}`
    );

  const { isLoading, data: observations } = useQuery(
    'observations',
    fetchObservations
  );

  const getVideoId = observationVideoId(sportingEventCollection);
  const currentSession = Session.current();

  const resourceGroupName = currentSession?._currentResource?.name;
  if (isLoading) {
    return null;
  }

  const observationsFiltered = observations
    .filter(
      (observation) =>
        filter['videoIds'].length === 0 ||
        filter['videoIds'].includes(getVideoId(observation))
    )
    .filter(
      (observation) =>
        filter['roles'].length === 0 ||
        filter['roles'].includes(observation.creatorRoleName)
    );
  return (
    <AssignmentProvider learningLine={learningLine.toJS()} match={match}>
      <div className={'page-title--group'}>
        <h2>{learningLine.name}</h2>
        <AssignmentDetail />
        {window.isSkillReflect && (
          // todo: Check if we want to disable this for SR aswell.
          <h3>
            {resourceGroupName} - {t('lastUpdated')}{' '}
            <DaysAgo date={developmentPlan.lastUpdated(learningLine)} />
          </h3>
        )}

        {!learningLine.id.includes('knkv') && ( // todo: only show when not in actual learning environment.
          <div className="fixed-bottom-right learningline__filters">
            <UnreadMessagesInLearningLine learningLine={learningLine} />
            <LearningLineFilter
              values={filter}
              onChange={setFilter}
              learningLine={learningLine}
              observations={observations}
            />
          </div>
        )}
      </div>

      <div className="learning-line">
        <div className="row learning-lines-detail">
          {learningLine.phases.map((phase, phaseIdx) => (
            <LearningPhase
              key={phase.phaseId}
              phase={phase}
              learningLine={learningLine}
              developmentPlan={developmentPlan}
              observations={observationsFiltered}
            />
          ))}
        </div>
      </div>
      {/*<TaskModal />*/}
    </AssignmentProvider>
  );
};

const LearningLineDetails = LearningLineDetailsFC;

export { LearningLineDetails };
