import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../../lib/Loading';
import UploadVideo from '../../match/components/UploadVideo';

import SportingEventCollection from '../../../domain/SportingEvent';
import { Session } from '../../../domain/Session';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Comment } from './comments/comment';

import sweetAlert from 'lib/sweetAlert';
import { useQuery, useQueryClient } from 'react-query';
import { VersionVideoPlayer } from './VersionVideoPlayer';
import { Error } from '../../../lib/PlaceHolder';
import { useAssignmentContext } from '../assignments/assignmentContext';
import { Versions } from './Versions';

export const VideoTask = ({ task }) => {
  const [isBusy, setIsBusy] = useState(false);
  const assignmentContext = useAssignmentContext();
  const assignment = assignmentContext.getAssignmentByTaskID(task.taskId);

  // Always fetch newest data when opening taskModal.
  const versions = assignment?.versions ?? [];
  const activeVersion = versions.find((version) => !version.archived);

  const isTeacher = assignmentContext.isTeacher;
  const isTrainee = assignmentContext.isTrainee;

  const textAreaRef = useRef();

  const practice = assignmentContext.getPracticeByPracticeId(task.practiceId);

  async function onVideoUploaded(videoId, sportingEvent) {
    const data = {
      submissionTRN: sportingEvent.get('sportingEventTRN'),
      taskTRN: task.taskTRN,
      name: `${practice.name} - ${task.name}`,
      comment: getComment(),
    };
    return await assignmentContext.addVersion(data, assignment);
  }

  function getComment() {
    return textAreaRef.current.value;
  }

  const { t } = useTranslation('module.development.tasks');

  if (!activeVersion && isTrainee) {
    return (
      <div>
        <div className="form-group">
          <label>
            <b>{t('form.noteForTeacher')}</b>
          </label>
          <textarea
            rows={2}
            disabled={isBusy}
            placeholder={t('form.noteForTeacherPlaceholder', {
              type: `${t('video')}`,
            })}
            className={'form-control'}
            ref={textAreaRef}
          />
        </div>
        <UploadVideo
          onStart={() => setIsBusy(true)}
          onUploaded={(videoId, sportingEvent) => {
            onVideoUploaded(videoId, sportingEvent);
          }}
          hideModal={true}
          task={task}
          assignmentName={practice.name}
          type={'assignment'}
        />
      </div>
    );
  } else {
    return null;
  }
};

export const VideoTaskVersion = ({ version, assignment, openOnLoad }) => {
  const [sportingEvent, setSportingEvent] = useState();
  const currentSession = Session.current();
  const { t } = useTranslation('module.development.tasks');

  const [busy, setBusy] = useState(false);

  const queryClient = useQueryClient();
  const assignmentContext = useAssignmentContext();

  const textAreaRef = useRef();
  const rejectButtonRef = useRef();
  const requestChangesButtonRef = useRef();
  function getVersionFeedback() {
    return textAreaRef.current.value;
  }

  const isTeacher = assignmentContext.isTeacher;
  const isTrainee = assignmentContext.isTrainee;

  useEffect(() => {
    (async () => {
      await SportingEventCollection.remove([version.submissionTRN.slice(-36)]); // make sure we fetch new ones.
      const assignmentSportingEvents = await SportingEventCollection.getOrFetch(
        version.submissionTRN.slice(-36)
      );
      setSportingEvent(assignmentSportingEvents);
    })();
  }, []);

  if (version.sportingEventId && !sportingEvent) {
    return <Loading type={'dots'} color={'black'} />;
  }

  const accept = async () => {
    // post approve
    let feedback = getVersionFeedback();
    setBusy(true);
    assignmentContext.acceptVersion(assignment.assignmentId, feedback);
  };

  const reject = async () => {
    let feedback = getVersionFeedback();
    if (!feedback) {
      // Cannot submit without feedback..
      window.alert('geen feedback gegeven');
      return;
    }
    setBusy(true);
    assignmentContext.rejectVersion(assignment.assignmentId, feedback);
  };

  const requestChanges = async () => {
    let feedback = getVersionFeedback();
    if (!feedback) {
      // Cannot submit without feedback..
      window.alert('geen feedback gegeven');
      return;
    }
    setBusy(true);
    assignmentContext.requestChangesVersion(assignment.assignmentId, feedback);
  };

  const retract = async () => {
    setBusy(true);

    sweetAlert({
      title: t('retractVideoWarning'),
      text: t('retractVideoMessage'),
      dangerMode: true,
      buttons: [t('common:cancel'), t('common:confirm')],
    }).then((willDelete) => {
      if (willDelete) {
        setBusy(false);
        assignmentContext.retractVersion(assignment.assignmentId, null);
      } else {
        setBusy(false);
      }
    });
  };
  if (!sportingEvent) {
    return (
      <div className="version__video">
        <Skeleton
          style={{
            height: '38px',
            width: '100%',
            marginBottom: '18px',
            borderRadius: '7px',
          }}
        />
      </div>
    );
  }
  if (sportingEvent && sportingEvent.hasVideo() && !version.archived) {
    return (
      <div className={`${busy ? 'is-busy' : ''}`}>
        <div className={`version__video ${version.state}`}>
          <div className={'version-video__titlebar'}>
            <div className="title">
              {sportingEvent?.tags?.fileNames.join(',') ?? sportingEvent.label}
            </div>
            <div className="version__state">{t(version.state)} </div>
          </div>
          <VersionVideoPlayer version={version} sportingEvent={sportingEvent} />
        </div>
        <div className="version-actions">
          {(version.assessmentComment || version.comment) && (
            <div className="version-comment-row">
              {version.comment && (
                <Comment
                  userId={version.submitterUserId}
                  comment={version.comment}
                />
              )}
              {version.assessmentComment && (
                <Comment
                  userId={version.assessorUserId}
                  comment={version.assessmentComment}
                />
              )}
            </div>
          )}

          {version.state !== 'accepted' && isTrainee && (
            <button
              disabled={busy}
              onClick={retract}
              className={'btn btn-link'}
            >
              {t('retractVideo')}
            </button>
          )}
          {version.state !== 'accepted' && isTeacher && (
            <>
              <div className="version-feedback">
                <label>{t('form.feedbackForTrainee')}</label>
                <textarea
                  ref={textAreaRef}
                  disabled={busy}
                  className={'form-control'}
                  rows={3}
                  onChange={(e) => {
                    if (e.target.value) {
                      requestChangesButtonRef.current.disabled = false;
                    } else {
                      requestChangesButtonRef.current.disabled = true;
                    }
                  }}
                />
              </div>
              <button
                onClick={accept}
                disabled={busy}
                className={'btn btn-primary'}
              >
                {t('form.approve')}
              </button>
              {false && (
                <button
                  ref={rejectButtonRef}
                  onClick={() => {
                    reject();
                  }}
                  disabled={busy}
                  className={'btn btn-primary btn-danger'}
                >
                  {t('form.reject')}
                </button>
              )}

              <button
                ref={requestChangesButtonRef}
                onClick={() => {
                  requestChanges();
                }}
                disabled={busy}
                className={'btn btn-link'}
              >
                {t('form.requestChanges')}
              </button>
            </>
          )}
        </div>
        {busy && (
          <div className="loading-wrapper">
            <Loading
              type={'spinner'}
              size={60}
              color={'var(--primary-color)'}
            />
          </div>
        )}
      </div>
    );
  }
  if (sportingEvent && sportingEvent.hasVideo() && version.archived) {
    return (
      <VersionSportingEvent
        openOnLoad={openOnLoad}
        version={version}
        sportingEvent={sportingEvent}
      />
    );
  }
  // No sportingEvent set.
  // Should not really happen tho..
  return (
    <div>
      <Error />
    </div>
  );
};

const VersionSportingEvent = ({ version, sportingEvent, openOnLoad }) => {
  const [showVideo, setShowVideo] = useState(openOnLoad);
  const { t } = useTranslation('module.development.tasks');
  const messageCount = (version.userComment ? 1 : 0) + (version.note ? 1 : 0);
  return (
    <div className={`version-sportingevent ${version.state}`}>
      <div className="version-row">
        {sportingEvent.hasVideo() && (
          <div
            onClick={() => setShowVideo(!showVideo)}
            className="d-flex align-content-center align-items-center clickable version-row__meta"
          >
            {!showVideo && (
              <div className="">
                <i className={'i-chevron-down i-xs i-dark'}></i>
              </div>
            )}
            {showVideo && (
              <div className="">
                <i className={'i-chevron-up i-xs i-dark'}></i>
              </div>
            )}

            <div className="date">
              {moment(sportingEvent.scheduledAt).format('D-M-Y')}
            </div>
            <div className="title">
              {sportingEvent?.tags?.fileNames?.join(',')}
            </div>
            {messageCount > 0 && (
              <div className="comments">
                <i className={'i-messages i-xs'}></i>
              </div>
            )}
            <div className="version__state">{t(version.state)} </div>
          </div>
        )}
      </div>
      {showVideo && sportingEvent.hasVideo() && (
        <div className="version__video">
          <VersionVideoPlayer version={version} sportingEvent={sportingEvent} />
        </div>
      )}
      {showVideo && (
        <div className="version-comment-row">
          {version.comment && (
            <Comment
              userId={version.submitterUserId}
              comment={version.comment}
            />
          )}
          {version.assessmentComment && (
            <Comment
              userId={version.assessorUserId}
              comment={version.assessmentComment}
            />
          )}
        </div>
      )}
    </div>
  );
};
