import {
  AssignmentProvider,
  useAssignmentContext,
} from '../assignments/assignmentContext';
import { useTranslation } from 'react-i18next';
import { Session } from '../../../domain/Session';
import React from 'react';
import { Page } from '../../../lib/Page';
import Loading from '../../../lib/Loading';
import { LearningPhase } from '../components/learning-phase';
import { CoursePhase } from './CoursePhase';
import { AssignmentDetail } from '../assignments/AssignmentDetail';
import { TaskModal } from '../assignments/TaskModal';
import { Checkbox } from '../../../lib/Checkbox';
import { InputCheckboxRadio } from '../../../lib/InputCheckBoxRadio';

export const CourseDetail = ({ match }) => {
  return (
    <AssignmentProvider match={match}>
      <CourseDetailContent />
    </AssignmentProvider>
  );
};

const CourseDetailContent = () => {
  const assignmentContext = useAssignmentContext();

  const learningLine = assignmentContext.getLearningLine();

  const { t } = useTranslation('module.development');
  const currentSession = Session.current();
  const resourceGroupName =
    currentSession?._currentResource?.name ??
    currentSession.currentMembership()?.targetResourceName;
  if (!learningLine) {
    return <Loading type={'fullscreen'} />;
  }
  const pageProps = {
    title: learningLine.name,
    breadcrumbs: [
      { path: '/', title: 'Home' },
      { title: 'cursussen', path: '/development/courses' },
      { title: learningLine.name },
    ],
    className: 'page--development development--overview',
    // subtitle: () => {
    //   return (
    //     <div className={'page-title--group'}>
    //       <h2>
    //         {learningLine.name} | {resourceGroupName}
    //       </h2>
    //     </div>
    //   );
    // },
  };

  const Layout = AssignmentDetail;

  const hideOverview = assignmentContext.getCurrentTask();
  return (
    <Page {...pageProps}>
      <div
        className={`learningline-swap-layout ${
          hideOverview ? 'is-opened' : ''
        }`}
      >
        <Layout />
        <div className="learning-line">
          <div className="row learning-lines-detail">
            {learningLine.phases.map((phase, phaseIdx) => (
              <CoursePhase phase={phase} key={phaseIdx} />
            ))}
          </div>
        </div>
        <AdminToggles />
      </div>
    </Page>
  );
};

const AdminToggles = () => {
  const assignmentContext = useAssignmentContext();
  const currentSession = Session.current();

  const allowedUserIds = [
    'c318c138-30fa-11e9-9451-29ce3e8d6d1d', // ttv-admin
    'e55de40c-dd44-11e9-a71c-5727a4fd3c59', // Remco
    '5d187a20-2fc4-11eb-83b8-0b8e94c1bb2f', // Pleun
  ];
  if (!allowedUserIds.includes(currentSession.user.realUserId)) {
    return null;
  }

  return (
    <div style={{ position: 'fixed', right: '0px', bottom: '0px' }}>
      <InputCheckboxRadio
        label={'Teacher'}
        checked={assignmentContext.isTeacher}
        onChange={(v) => {
          assignmentContext.setIsTeacher(!v);
          assignmentContext.setIsTrainee(v);
        }}
      />
      <InputCheckboxRadio
        label={'Trainee'}
        checked={assignmentContext.isTrainee}
        onChange={(v) => {
          assignmentContext.setIsTrainee(!v);
          assignmentContext.setIsTeacher(v);
        }}
      />

      {/*<InputCheckboxRadio*/}
      {/*  label="nieuwe layout"*/}
      {/*  checked={assignmentContext.layout === 'new'}*/}
      {/*  onChange={(v) => {*/}
      {/*    assignmentContext.setLayout(v ? 'modal' : 'new');*/}
      {/*  }}*/}
      {/*/>*/}
    </div>
  );
};
