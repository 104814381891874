import React, { useRef, useState } from 'react';
import { Session } from '../../../domain/Session';
import { useTranslation } from 'react-i18next';

import { Comment } from './comments/comment';

import { useAssignmentContext } from '../assignments/assignmentContext';
import { Versions } from './Versions';

export const QuestionAnswerTask = ({ task }) => {
  const assignmentContext = useAssignmentContext();
  const assignment = assignmentContext.getAssignmentByTaskID(task.taskId);
  // Always fetch newest data when opening taskModal.
  // dependent query, make sure we have most recent data when invalidating or setting first time assignment.
  const versions = assignment?.versions ?? [];
  const activeVersion = versions.find((version) => !version.archived);
  const isTrainee = assignmentContext.isTrainee;

  const textAreaRef = useRef();

  function getComment() {
    return textAreaRef.current.value;
  }

  const practice = assignmentContext.getPracticeByPracticeId(task.practiceId);

  async function onSubmit() {
    const data = {
      submissionTRN: 'trn:not-set',
      taskTRN: task.taskTRN,
      name: `${practice.name} - ${task.name}`,
      comment: getComment(),
    };
    return await assignmentContext.addVersion(data, assignment);
  }

  const { t } = useTranslation('module.development.tasks');

  return (
    <div className={'documentTask mt-3'}>
      {!activeVersion && isTrainee && (
        <div className="version-feedback mb-3">
          <label>
            <b>{t('answerAssignmentQA')}</b>
          </label>
          <textarea
            rows={2}
            disabled={
              assignmentContext.isFetching || assignmentContext.isSubmitting
            }
            placeholder={t('answerAssignmentPlaceholderQA')}
            className={'form-control'}
            ref={textAreaRef}
          />
          <button
            onClick={() => onSubmit()}
            disabled={
              assignmentContext.isFetching ||
              assignmentContext.isSubmitting ||
              !getComment
            }
            className={'btn btn-primary mt-3'}
          >
            {t('form.submitQA')}
          </button>
        </div>
      )}
    </div>
  );
};

export const QuestionAnswerVersion = ({ task, version }) => {
  const currentSession = Session.current();
  const tenantId = currentSession.currentTenantId();
  const assignmentContext = useAssignmentContext();

  let userId;
  switch (tenantId) {
    case 'nl_korfball_knkv':
      userId = 'knkv-opleidingen';
      break;
    case 'nl_handball_nhv':
      userId = 'nhv-opleidingen';
      break;
    default:
      userId = 'teamtv-system';
      break;
  }

  return (
    <div className={`version-sportingevent ${version.state}`}>
      <div className="version-feedback">
        {version.comment && (
          <div className="version-comment-row">
            {version.comment && (
              <Comment
                userId={version.submitterUserId}
                comment={version.comment}
              />
            )}
            {/*todo: add check if task is from knkv or other educators (teamtv-generated)*/}
            <Comment
              userId={userId}
              comment={task.answer}
              currentUser={assignmentContext?.isTeacher ? userId : ''} // Make it seem like your own response when you are teachter
            />
          </div>
        )}
      </div>
    </div>
  );
};
