import { useQuery } from 'react-query';
import { Modal } from 'lib';
import api from 'utils/ApiClient';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Loading from 'lib/Loading';
import { Formik } from 'formik';

export const AddCourseModal = ({ isOpen, onClose, callback }) => {
  const { t } = useTranslation('module.development.learningGroups');
  const fetchCourses = async () => {
    const response = await api.get('courses');
    return response.data;
  };

  const { data: courses, isFetched } = useQuery('courses', fetchCourses);

  if (!isOpen || !isFetched || !courses.length) {
    return null;
  }

  const options = courses.map((c) => {
    return { label: `${c.name} (${c.courseId})`, value: c.courseId };
  });
  const validate = (values) => {
    let errors = {};
    if (!values.name) {
      errors.name = true;
    }
    if (!values.courseId) {
      errors.courseId = true;
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    await api.post('learningGroups', {
      ...values,
      trainees: [],
    });
    callback();
  };

  return (
    <Modal onCloseClick={onClose}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ name: '', courseId: null }}
        validate={validate}
      >
        {({
          handleSubmit,
          values,
          submitCount,
          errors,
          setFieldValue,
          onReset,
          isSubmitting,
        }) => {
          const validClassName = (key) => {
            if (!submitCount) {
              return '';
            }

            return errors[key] ? 'is-invalid' : 'is-valid';
          };
          return (
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title">{t('addCourse')}</h5>
              </div>
              <div className="modal-body">
                <div className={'row'}>
                  <div className="col-12">
                    <div className={`form-group ${validClassName('name')}`}>
                      <label>{t('courseName')}</label>
                      <input
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                        className={'form-control'}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className={`form-group ${validClassName('courseId')}`}>
                      <label>{t('course')}</label>
                      <Select
                        options={options}
                        menuPortalTarget={document.getElementById(
                          'menuPortalTarget'
                        )}
                        onChange={(v) => setFieldValue('courseId', v.value)}
                        value={options.find((o) => o.value === values.courseId)}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="form-actions">
                  <button
                    type={isSubmitting ?? 'submit'}
                    className="btn btn-primary"
                  >
                    {t('addCourse')}{' '}
                    {isSubmitting && (
                      <Loading type={'spinner'} color={'white'} size={16} />
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => onClose()}
                  >
                    {t('common:cancel')}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};
