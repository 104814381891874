import learningLineCollection from './LearningLine';
import learningLineTreeCollection from './LearningLineTree';

class LearningLineQueryService {
  static async learningLinesByPerson(personId) {
    await learningLineCollection.fetchIfEmpty();
    // no logic here yet
    return learningLineCollection.toArray();
  }

  static async learningLinesByTeam(teamId) {
    await learningLineCollection.fetchIfEmpty();
    // no logic here yet
    return learningLineCollection.toArray();
  }

  static async learningLines(options = { includeCustom: false }) {
    if (options.refresh) {
      await learningLineCollection.fetch();
    } else {
      await learningLineCollection.fetchIfEmpty();
    }

    let learningLines = learningLineCollection.toArray();
    if (!options.includeCustom) {
      learningLines = learningLines.filter(
        (learningLine) => learningLine.learningLineId !== 'custom'
      );
    }
    return learningLines;
  }

  static async learningLineById(learningLineId) {
    await learningLineCollection.fetchIfEmpty();

    return learningLineCollection.getOrFetch(learningLineId);
  }

  static async learningLineTree() {
    return learningLineTreeCollection.fetch();
  }

  static indicatorOfIndicatorId(indicatorId) {
    for (const learningLine of learningLineCollection.toArray()) {
      const indicator = learningLine.indicatorOfIndicatorId(indicatorId);
      if (indicator !== null) {
        return indicator;
      }
    }
    return null;
  }
}

export { LearningLineQueryService };
