import { VideoTask } from './VideoTask';
import { ReadingTask } from './ReadingTask';
import { DocumentTask } from './DocumentTask';
import { TextTask } from './TextTask';
import { QuestionAnswerTask } from './QuestionAnswerTask';
import { useAssignmentContext } from '../assignments/assignmentContext';
import Loading from '../../../lib/Loading';
import { gotoRoute } from '../../route';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Session } from '../../../domain/Session';
import { Versions } from './Versions';

export const LearninglineTask = ({ task, assignment }) => {
  let Element;

  const assignmentContext = useAssignmentContext();

  switch (task.taskType) {
    case 'document_or_video':
      Element = <ChoiceTask assignment={assignment} task={task} />;
      break;
    case 'read':
      Element = <ReadingTask assignment={assignment} task={task} />;
      break;
    case 'document':
      Element = <DocumentTask task={task} assignment={assignment} />;
      break;
    case 'video':
      Element = <VideoTask task={task} assignment={assignment} />;
      break;
    case 'text':
      Element = <TextTask task={task} assignment={assignment} />;
      break;
    case 'question_answer':
      Element = <QuestionAnswerTask task={task} assignment={assignment} />;
      break;
  }

  if (assignmentContext.isFetching) {
    Element = <Loading type={'spinner'} />;
  }

  return (
    <div className={'learningLineTask'}>
      <div className="task-body">
        <h3>{task?.name}</h3>
        <div dangerouslySetInnerHTML={{ __html: task.body }}></div>
      </div>
      <div className="py-2">{Element}</div>
      <Versions task={task} />
      <div
        style={{
          paddingTop: '1.2rem',
          marginTop: '1em',
        }}
      >
        {task?.competencies?.length > 0 &&
          task?.competencies.map((competencie) => {
            return (
              <div>
                <h6 className={'muted'}>
                  Bij deze opdracht horen de volgende competenties.
                </h6>
                <div className={'badge-pill'}>{competencie.name}</div>
              </div>
            );
          })}
        {task?.requirement?.map((requirement) => {
          let parts = requirement.split(':');
          const _task = assignmentContext.getTaskByTaskId(
            parts[parts.length - 1]
          );
          if (!_task) {
            return null;
          }
          return (
            <button
              onClick={() => {
                gotoRoute('development.tdp-update', {
                  learningLineId:
                    assignmentContext.getLearningLine().learningLineId,
                  taskId: _task.taskId,
                });
              }}
              className={'btn btn-outline-primary btn-primary'}
            >
              {_task?.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const ChoiceTask = ({ task }) => {
  const [taskType, setTaskType] = useState(null);
  const assignmentContext = useAssignmentContext();
  const assignment = assignmentContext.getAssignmentByTaskID(task.taskId);

  const { t } = useTranslation('module.development.tasks');
  // Always fetch newest data when opening taskModal.
  // dependent query, make sure we have most recent data when invalidating or setting first time assignment.
  const currentSession = Session.current();

  const isTeacher = assignmentContext.isTeacher;
  const isTrainee = assignmentContext.isTrainee;

  const versions = assignment?.versions ?? [];
  const activeVersion = versions.find((version) => !version.archived);

  return (
    <div>
      {!activeVersion && isTrainee && (
        <>
          {' '}
          <label>
            <b>{t('taskType.choice.label')}</b>
          </label>
          <Select
            options={[
              { label: t('taskType.choice.video'), value: 'video' },
              { label: t('taskType.choice.document'), value: 'document' },
            ]}
            value={taskType}
            onChange={(v) => {
              setTaskType(v);
            }}
          />
        </>
      )}
      {taskType?.value === 'document' && (
        <DocumentTask task={task} assignment={assignment} />
      )}
      {taskType?.value === 'video' && (
        <VideoTask task={task} assignment={assignment} />
      )}
    </div>
  );
};
