import React from 'react';
import { Progress } from 'reactstrap';

import './index.scss';
import { observer } from 'mobx-react';
import { DaysAgo } from 'lib/DaysAgo';
import { useTranslation, withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getRoutePath, gotoPath } from 'modules/route';
import { PhaseCompletedLottie } from './medal/PhaseCompleted';

import _ from 'lodash';
import { Tooltip } from '../../../lib/Tooltip';
import { useAssignmentContext } from '../assignments/assignmentContext';

const MAX_PHASES = 15;

const LearningLine = withTranslation('module.development')(
  observer(({ t, learningLine, developmentPlan, children }) => {
    const lastUpdated = developmentPlan.lastUpdated(learningLine);
    return (
      <div className="card" data-learningline-id={learningLine.id}>
        {learningLine?.image && <img src={learningLine.image} />}
        <div className="card-body card-body--title">
          <h5>{learningLine.name}</h5>
          {lastUpdated && (
            <p className="last-updated">
              {t('lastUpdated')} <DaysAgo date={lastUpdated} />
            </p>
          )}
          {developmentPlan.learningLinePhasesComplete(learningLine) && (
            <div className="phase-medal">
              <PhaseCompletedLottie />
            </div>
          )}
        </div>
        <div className="card-body">
          {!lastUpdated && <p className="last-updated">{t('notYetUpdated')}</p>}
          <NavLink
            className="btn btn-primary"
            to={getRoutePath('development.tdp-update', {
              learningLineId: learningLine.id,
            })}
          >
            <i className={'i-chevron-right i-light i-xs'} />
            {t('updateDevelopment')}
          </NavLink>

          <div className="learning-lines-detail-phases">
            {learningLine.phases.slice(0, MAX_PHASES).map((phase, phaseIdx) => (
              <LearningLinePhase
                key={phase.phaseId}
                phase={phase}
                developmentPlan={developmentPlan}
              />
            ))}
            {learningLine.phases.length > MAX_PHASES && (
              <div
                className="learning-lines-detail-phase"
                style={{ textAlign: 'center' }}
              >
                ...
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    );
  })
);

const LearningLinePhase = ({ developmentPlan, phase }) => {
  const phaseTasks = _.compact(
    _.flatMap(phase?.practices.map((p) => p?.tasks?.toJS()))
  );
  const { t } = useTranslation('module.development.tasks');
  const assignmentContext = useAssignmentContext();
  const assignments = assignmentContext?.assignments ?? [];
  if (phaseTasks.length > 0 && assignmentContext) {
    const notification = assignmentContext.getActionStatus(
      phaseTasks,
      assignments
    );
    return (
      <div
        className="learning-lines-detail-phase"
        data-phase-id={phase.phaseId}
      >
        <div className="learning-lines-detail-phase-title">
          {phase.name}
          {notification && (
            <Tooltip tooltip={t(notification)}>
              <div
                className={`learning-lines-detail-phase-notification notification--${notification}`}
              />
            </Tooltip>
          )}
        </div>
        <div className="learning-lines-detail-phase-progress">
          <div className="learning-phase-progress">
            <Progress
              value={assignmentContext.getCompletionStatusPercentage(
                phaseTasks,
                assignments
              )}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="learning-lines-detail-phase" data-phase-id={phase.phaseId}>
      <div className="learning-lines-detail-phase-title">{phase.name}</div>
      <div className="learning-lines-detail-phase-progress">
        <div className="learning-phase-progress">
          <Progress value={developmentPlan.phaseProgress(phase)} />
        </div>
      </div>
    </div>
  );
};

export { LearningLine };
