import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { Observer } from 'mobx-react';
import { useTranslation, withTranslation } from 'react-i18next';
import Loading from 'lib/Loading';
import { Modal } from 'lib/Modal';
import { Checkbox } from 'lib/Checkbox';
import { LearningLineStatusIndicator } from 'lib/LearningLineStatusIndicator';
import { VideoFragmentsPlayer } from 'lib/VideoFragmentsPlayer';
import {
  update as updateObservation,
  delete_ as deleteObservation,
  changeset,
  ratingColorByKey,
} from 'domain/Observation';
import { merge, cloneDeep, groupBy, forEach } from 'lodash';

import warning from 'img/icons/warning_orange.svg';
import { Tooltip } from 'lib/Tooltip';

import {
  ObservationsEditCollectionContextProvider,
  ObservationsEditCollectionContext,
} from 'modules/observe/edit-collection-context';
import { DevelopmentPlanObservationDetails } from 'modules/observe/containers/observation-details/development-plan';
import { CustomObservationDetails } from 'modules/observe/containers/observation-details/custom';
import { GetObservationUpdateMutation } from './utils/observationUpdateMutation';
import { GetObservationDeleteMutation } from './utils/observationDeleteMutation';
import { MultiStepModal } from '../../../lib/MultiStepModal';
import { LearninglineTask } from './learningline-task';
import { Session } from '../../../domain/Session';

import { useAssignmentContext } from '../assignments/assignmentContext';
import { gotoRoute } from '../../route';

const LearningIndicatorFC = ({
  indicator,
  phaseId,
  developmentPlan,
  observations,
  examples = [],
}) => {
  const { t } = useTranslation('module.development');
  const queryClient = useQueryClient();
  const assignmentContext = useAssignmentContext();
  const observationsForIndicator = observations.filter((obs) => {
    if (indicator.isUnmarked) {
      return !obs.attributes.indicatorId && obs.attributes.phaseId == phaseId;
    } else {
      return obs.attributes.indicatorId === indicator.indicatorId;
    }
  });
  const observationsGroupedByRating = groupBy(
    observationsForIndicator,
    (obs) => obs.attributes.rating || 'unknown'
  );
  const hasObservations = observationsForIndicator.length > 0;
  const classNames = `learning-indicator ${
    hasObservations ? 'btn-group' : ''
  } ${indicator.isUnmarked ? 'unmarked' : ''}`;

  const currentSession = Session.current();
  const attributesOptions = { personId: [] };

  const [loading, setLoading] = useState(false);

  const observationUpdateMutation = GetObservationUpdateMutation(queryClient);

  const observationDeleteMutation = GetObservationDeleteMutation(queryClient);

  const [taskModal, setTaskModal] = useState();

  const isTeacher = assignmentContext.isTeacher;
  const isTrainee = assignmentContext.isTrainee;

  const ButtonGroup = () => {
    if (hasObservations) {
      return (
        <div className="learningline-status-indicator__buttongroup">
          <ObservationsEditCollectionContext.Consumer>
            {({ current, setCurrent }) => {
              if (!current) {
                return null;
              }

              let ObservationDetailsComponent = null;
              switch (true) {
                case current.code === 'DEVELOPMENT-PLAN':
                  ObservationDetailsComponent =
                    DevelopmentPlanObservationDetails;
                  break;
                case current.code === 'CUSTOM':
                  ObservationDetailsComponent = CustomObservationDetails;
                  break;
              }
              return (
                current && (
                  <ObservationDetailsComponent
                    observation={current}
                    onCancel={() => setCurrent()}
                    onDelete={() =>
                      observationDeleteMutation.mutate({
                        observationId: current.observationId,
                      })
                    }
                    onClose={() => setCurrent()}
                    onUpdateObservation={(attrs) => {
                      observationUpdateMutation.mutate({
                        observation: current,
                        params: attrs,
                      });
                    }}
                    attributesOptions={attributesOptions}
                  />
                )
              );
            }}
          </ObservationsEditCollectionContext.Consumer>
          {['positive', 'neutral', 'negative', 'unknown'].map((rating) => {
            if (typeof observationsGroupedByRating[rating] === 'undefined') {
              return null;
            }
            const observationsForRating = observationsGroupedByRating[rating];

            return (
              <ObservationsEditCollectionContext.Consumer key={rating}>
                {({ startEdit, setSelector }) => {
                  return (
                    <button
                      type="button"
                      onClick={(event) => {
                        event.stopPropagation();
                        const selector = (obs) =>
                          (obs.attributes.rating || 'unknown') === rating;
                        const selectedObservations =
                          observationsForRating.filter(selector);

                        setSelector(selector);
                        startEdit({
                          id: selectedObservations[0].observationId,
                        });
                      }}
                      className={`learningline-status-indicator__button rating-${ratingColorByKey(
                        rating
                      )}`}
                    >
                      {observationsForRating.length}
                    </button>
                  );
                }}
              </ObservationsEditCollectionContext.Consumer>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={classNames} data-indicator-id={indicator.indicatorId}>
      <ObservationsEditCollectionContextProvider
        observations={observationsForIndicator}
      >
        <Observer>
          {() => {
            const update = async (isMastered) => {
              return developmentPlan.addIndicatorEvaluation(
                indicator.indicatorId,
                isMastered
              );
            };
            const indicatorStatus = () => {
              const statusses = [];
              const checkTasks = indicator.tasks;
              checkTasks.forEach((task, index) => {
                const _assignment = assignmentContext.getAssignmentByTaskID(
                  task.taskId
                );
                if (
                  task.taskType === 'read' &&
                  _assignment?.state === 'submitted'
                ) {
                  statusses.push('accepted');
                } else {
                  statusses.push(_assignment?.state);
                }
              });
              if (statusses.length >= 1 && statusses.includes('submitted')) {
                return `submitted ${isTeacher ? 'show-notification' : ''}`;
              }
              if (statusses.length >= 1 && statusses.includes('rejected')) {
                return `rejected ${isTrainee ? 'show-notification' : ''}`;
              }
              if (
                statusses.length >= 1 &&
                statusses.includes('changes_requested')
              ) {
                return `changes_requested ${
                  isTrainee ? 'show-notification' : ''
                }`;
              }
              if (
                statusses.length >= 1 &&
                statusses.includes('accepted') &&
                assignmentContext.getCompletionStatusBoolean(checkTasks) // All assignments in current indicator should be marked successfull
              ) {
                return 'accepted';
              }
              return 'empty';
            };
            // Indicator when practice has tasks
            if (indicator?.tasks && indicator.tasks.length > 0) {
              return (
                <LearningLineStatusIndicator
                  loading={loading}
                  Buttons={ButtonGroup}
                  status={indicatorStatus()}
                  dataId={indicator.id}
                  onIconClick={() => {
                    isTeacher && assignmentContext.refresh();
                    let routeArgs = {
                      taskId: assignmentContext.getNextTaskFromPracticeId(
                        indicator.indicatorId
                      ).taskId,
                      learningLineId:
                        assignmentContext.getLearningLine().learningLineId,
                    }; // Refresh content on opening for teachers
                    gotoRoute('development.tdp-update', routeArgs);
                  }}
                  icon={
                    <LearningIndicatorIcon
                      status={assignmentContext.getActionStatus(
                        indicator.tasks
                      )}
                    />
                  }
                >
                  {indicator.name}{' '}
                  <span className="small">
                    {indicator?.tasks.length > 0 &&
                      assignmentContext.getCompletionStatusText(
                        indicator.tasks
                      )}{' '}
                    {t('module.development.tasks:tasks')}
                  </span>
                </LearningLineStatusIndicator>
              );
            }
            if (indicator.isUnmarked) {
              // Unmarked boxes should not be clickable
              return (
                <LearningLineStatusIndicator
                  loading={loading}
                  Buttons={ButtonGroup}
                  status={'warning'}
                  dataId={indicator.id}
                  icon={
                    <Tooltip tooltip={t('UnmarkedTip')}>
                      <img src={warning} />
                    </Tooltip>
                  }
                >
                  <b>{indicator.name}</b>
                </LearningLineStatusIndicator>
              );
            } else {
              // Indicator when practice has no tasks
              return (
                <LearningLineStatusIndicator
                  loading={loading}
                  dataId={indicator.id}
                  onIconClick={() => {
                    setLoading(true);
                    update(
                      !developmentPlan.isIndicatorMastered(
                        indicator.indicatorId
                      )
                    ).then(() => {
                      setLoading(false);
                    });
                  }}
                  Buttons={ButtonGroup}
                  icon={
                    developmentPlan.isIndicatorMastered(
                      indicator.indicatorId
                    ) ? (
                      <Tooltip tooltip={t('unmarkAsDone')}>
                        <span className="i-check i-light" />
                      </Tooltip>
                    ) : (
                      <Tooltip tooltip={t('markAsDone')}>
                        <span className="i-check i-light"></span>
                      </Tooltip>
                    )
                  }
                  status={
                    developmentPlan.isIndicatorMastered(indicator.indicatorId)
                      ? 'done'
                      : 'empty'
                  }
                >
                  <div>{indicator.name}</div>
                  {examples.length > 0 && (
                    <VideoFragmentsPlayer
                      videoFragments={examples}
                      title={indicator.name}
                    >
                      {({ isToggled, toggle }) => (
                        <a
                          className="learning-indicator__examplebutton"
                          onClick={toggle}
                        >
                          <i className={'i-playlist i-dark i-sm'} />
                          <span>Watch {examples.length} example(s)</span>
                        </a>
                      )}
                    </VideoFragmentsPlayer>
                  )}
                </LearningLineStatusIndicator>
              );
            }
          }}
        </Observer>
      </ObservationsEditCollectionContextProvider>
    </div>
  );
};

export const LearningIndicatorIcon = ({ indicatorStatus }) => {
  const assignmentContext = useAssignmentContext();
  const { t } = useTranslation('module.development');
  const tooltipTranslations = !assignmentContext.isTrainee
    ? 'forTeacher'
    : 'forTrainee';

  if (indicatorStatus === 'submitted') {
    return (
      <Tooltip
        tooltip={t(`module.development.tasks:${tooltipTranslations}.submitted`)}
      >
        <span className={'i-submitted i-dark'} />
      </Tooltip>
    );
  } else if (indicatorStatus === 'rejected') {
    return (
      <Tooltip
        tooltip={t(`module.development.tasks:${tooltipTranslations}.rejected`)}
      >
        <span className={'i-warning i-light'} />
      </Tooltip>
    );
  } else if (indicatorStatus === 'changes_requested') {
    return (
      <Tooltip
        tooltip={t(
          `module.development.tasks:${tooltipTranslations}.changes_requested`
        )}
      >
        <span className={'i-warning i-light'} />
      </Tooltip>
    );
  } else if (indicatorStatus === 'accepted') {
    return (
      <Tooltip
        tooltip={t(`module.development.tasks:${tooltipTranslations}.accepted`)}
      >
        <span className={'i-check i-light'} />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        tooltip={t(`module.development.tasks:${tooltipTranslations}.empty`)}
      >
        <span className={'i-add i-dark'} />
      </Tooltip>
    );
  }
};

const LearningIndicator = withTranslation('TODO')((props) => (
  <Observer>
    {() => {
      return <LearningIndicatorFC {...props} />;
    }}
  </Observer>
));

export { LearningIndicator };
