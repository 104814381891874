import { useAssignmentContext } from './assignmentContext';
import React, { useEffect, useState } from 'react';

import './assignments.scss';
import { gotoRoute } from '../../route';
import { InputCheckboxRadio } from '../../../lib/InputCheckBoxRadio';
import _ from 'lodash';
import { PhaseCompletedLottie } from '../components/medal/PhaseCompleted';
import { LearningLineStatusIndicator } from '../../../lib/LearningLineStatusIndicator';
import { LearningIndicatorIcon } from '../components/learning-indicator';
import { Tooltip } from '../../../lib/Tooltip';
import { useTranslation } from 'react-i18next';

export const AssignmentDetail = () => {
  const assignmentContext = useAssignmentContext();
  if (assignmentContext.getCurrentTask()) {
    const _task = assignmentContext.getTaskByTaskId(
      assignmentContext.getCurrentTask()
    );
    const assignmentContent = assignmentContext.getPracticeModalContent(
      _task?.practiceId
    );
    return (
      <div className={'assignment-view'}>
        <div
          key={assignmentContent.practice.practiceId}
          className="assignment-view__assignment"
        >
          <a
            className={'course-overview-link'}
            onClick={() => {
              gotoRoute('development.course-detail', {
                learningLineId:
                  assignmentContext.getLearningLine().learningLineId,
              });
            }}
          >
            <i className={'i-corner-left-up i-xs'} />
            {assignmentContext.getLearningLine().name}
            <span className={'d-none d-md-inline'}>
              / {assignmentContent.phase.name} /
              {assignmentContent.practice.name}
            </span>
          </a>
          <AssignmentDetailMainView assignmentContent={assignmentContent} />
        </div>
        <div className="assignment-view__list">
          <AssignmentDetailSideBar
            key={assignmentContent.practice.practiceId + 'a'}
            assignmentContent={assignmentContent}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const AssignmentDetailMainView = ({ assignmentContent }) => {
  const assignmentContext = useAssignmentContext();
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);
  const routeArgs = {
    learningLineId: assignmentContext.getLearningLine().learningLineId,
  };
  if (
    assignmentContent?.nextStep &&
    assignmentContent.nextStep?.tasks[0]?.taskId // Next step needs to have atleast 1 task.
  ) {
    routeArgs.taskId = assignmentContent.nextStep.tasks[0]?.taskId;
  }
  const { t } = useTranslation('module.development.learningGroups');
  return (
    <div>
      {/*<h2>{assignmentContent.practice.name}</h2>*/}
      {assignmentContent.steps.map((step, index) => {
        return (
          <div key={index} className={'course-task'}>
            <span className={'course-task__order'}>
              Opdracht {`${index + 1}/${assignmentContent.steps.length}`}
            </span>

            <div className={`course-task__component state--${step.status}`}>
              {step.component}
            </div>
          </div>
        );
      })}
      {assignmentContent?.nextStep && (
        <div className="assignmentdetail-actions">
          <button
            className="btn btn-primary"
            onClick={() => {
              gotoRoute('development.course-detail', routeArgs);
            }}
          >
            {t('nextAssignment')}: {assignmentContent.nextStep?.name}
          </button>
        </div>
      )}
    </div>
  );
};

const AssignmentDetailSideBar = ({ assignmentContent }) => {
  const assignmentContext = useAssignmentContext();
  return (
    <div className={'course-sidebar'}>
      {assignmentContext
        .getLearningLine()
        .phases.filter((phase) => {
          // Phase -> practices need to have at least one task for this to display properly.
          return (
            phase?.practices.filter((practice) => practice.tasks.length > 0)
              .length > 0
          );
        })
        .map((phase, index) => (
          <CourseSidebarPhase
            phase={phase}
            active={phase.phaseId === assignmentContent?.phase?.phaseId}
            key={index}
          />
        ))}
    </div>
  );
};

const CourseSidebarPhase = ({ phase, active }) => {
  const [isOpen, setIsopen] = useState(active);
  const assignmentContext = useAssignmentContext();
  const current = assignmentContext.getCurrentTask();
  // Open when active always
  useEffect(() => {
    setIsopen(active);
  }, [active]);

  const phaseTasks = _.compact(
    _.flatMap(phase?.practices.map((p) => p?.tasks))
  );
  const isCompleted =
    assignmentContext.getCompletionStatusPercentage(phaseTasks) === 100;

  const CourseSidebarPractice = ({ practice }) => {
    const completionStatus = assignmentContext.getCompletionStatus(
      practice.tasks
    );
    const { t } = useTranslation('module.development');
    const active = practice.tasks.find((t) => t.taskId === current)
      ? 'is-active'
      : '';

    let routeArgs = {
      taskId: assignmentContext.getNextTaskFromPracticeId(practice.practiceId)
        .taskId,
      learningLineId: assignmentContext.getLearningLine().learningLineId,
    }; // Refresh content on opening for teachers
    const notification = assignmentContext.getActionStatus(practice.tasks);

    const openAssignment = () => {
      if (assignmentContext.isTeacher) {
        assignmentContext.refresh();
      }
      gotoRoute('development.course-detail', routeArgs);
    };
    const status = assignmentContext.getActionStatus(practice.tasks);
    return (
      <div className={`course-sidebar__practice ${active} status--${status}`}>
        <div className="left">
          <div className="indicator-icon" onClick={openAssignment}>
            {notification && (
              <Tooltip tooltip={t(notification)}>
                <div
                  className={`learning-lines-detail-phase-notification notification--${notification}`}
                />
              </Tooltip>
            )}
            <LearningIndicatorIcon indicatorStatus={status} />
          </div>
        </div>
        <div className="right">
          <label onClick={() => openAssignment()}>{practice.name}</label>
          <span className="small">{`${completionStatus[0]}/${completionStatus[1]} opdrachten`}</span>
        </div>
      </div>
    );
  };

  const Completion = () => {
    if (isCompleted) {
      return (
        <div className="percentage">
          <PhaseCompletedLottie width={22} />
        </div>
      );
    } else {
      return (
        <span className={'percentage'}>
          {assignmentContext.getCompletionStatusPercentage(phaseTasks)}%
        </span>
      );
    }
  };

  return (
    <div
      key={phase.phaseId}
      className={`course-sidebar__phase ${active ? 'is-active' : ''} ${
        isCompleted ? 'is-completed' : ''
      } `}
    >
      <div
        className="course-sidebar__phase-title"
        onClick={() => setIsopen(!isOpen)}
      >
        <Completion key={phase.phaseId} />
        <strong>{phase.name}</strong>
        <div className="toggle">
          {isOpen && <i className={'i-chevron-down i-dark'}></i>}
          {!isOpen && <i className={'i-chevron-up i-dark'}></i>}
        </div>
      </div>
      {isOpen && (
        <div>
          {phase.practices.map((practice) => {
            return (
              <CourseSidebarPractice
                key={practice.practiceId}
                practice={practice}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
