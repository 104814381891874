import { Model, Collection } from 'mobx-rest';

import { PersistentCollection } from './PersistentCollection';
import { baseDurationAfter, baseDurationBefore } from 'lib/Config';

import SportingEventCollection from 'domain/SportingEvent';
import api from '../utils/ApiClient';
import logger from '../utils/Logger';

const PLACEHOLDER_URL =
  'https://web-assets.teamtv.nl/global/video/no-video-final.mp4';

class Video extends Model {
  get primaryKey() {
    return 'videoId';
  }

  getFragmentUrl(startTime, endTime) {
    return this.rpc('getFragmentUrl', { startTime, endTime }).then(
      (mediaUrl) => {
        return mediaUrl;
      }
    );
  }

  async getPreviewUrl() {
    if (!this.previewUrl) {
      if (!this.has('hasHLs') || !this.get('hasHls')) {
        this.previewUrl = PLACEHOLDER_URL;
      } else {
        this.previewUrl = await this.getFragmentUrl(
          0,
          Math.min(5, this.duration)
        );
      }
    }
    return this.previewUrl;
  }

  getVideoFragment(startTime, endTime, description = null, labels = null) {
    if (startTime > this.duration) {
      console.warn(
        `Could not get video fragment: ${startTime} of ${this.duration}`
      );
    }
    return {
      startTime: parseInt(Math.max(0, startTime)),
      endTime: parseInt(Math.min(this.duration, endTime)),
      videoId: this.id,
      description,
      labels,
    };
  }

  getVideoFragmentUrlByVideoFagment(videoFragment) {
    if (this?.mediaUrl) {
      if (this.mediaUrl.indexOf('blob') !== -1) {
        return `${this.mediaUrl}#t=${videoFragment.startTime},${videoFragment.endTime}`;
      }
      return `${this.mediaUrl}&start=${videoFragment.startTime}&end=${videoFragment.endTime}`;
    } else {
      logger.warning(`video does not have a media url`, {
        videoId: `${this.videoId}`,
      });
      return PLACEHOLDER_URL;
    }
  }

  getVideoFragmentUrl(startTime, endTime) {
    const start = parseInt(Math.max(0, startTime));
    const end = parseInt(Math.min(this.get('content').duration, endTime));
    return `${this.mediaUrl}&start=${start}&end=${end}`;
  }

  get mediaUrl() {
    return this.has('mediaUrl') && this.get('mediaUrl');
  }

  get thumbnailUrl() {
    return this.has('thumbnailUrl') && this.get('thumbnailUrl');
  }

  async clipThumbnailUrl(startTime, size) {
    const { data: clipThumbnailUrl } = await api.get(
      `videos/${this.videoId}/getClipThumbnail`,
      {
        params: {
          time: startTime,
          size: size,
        },
      }
    );
    return clipThumbnailUrl || this.thumbnailUrl;
  }

  get state() {
    if (
      !this.has('mediaUrl') &&
      this.has('state') &&
      this.get('state') === 'ready'
    ) {
      // console.log(this);
      return 'expired';
    }
    return this.has('state') ? this.get('state') : 'no-state';
  }

  getObservationsDownloadUrl(description, observations) {
    const clips = observations.map((observation) => {
      let startTime = observation.startTime;
      let endTime = observation.endTime;
      startTime = parseInt(Math.max(0, startTime));
      endTime = parseInt(Math.min(this.get('content').duration, endTime));
      return {
        startTime,
        endTime,
      };
    });
    return this.rpc('getObservationsDownloadUrl', { description, clips });
  }

  getDownloadUrl() {
    return this.rpc('getDownloadUrl', {});
  }

  get parts() {
    return this.get('parts').toJS();
  }

  get tags() {
    return this.has('tags') ? this.get('tags') : {};
  }

  get duration() {
    return this.get('content').duration;
  }

  get tenantId() {
    return this.get('tenantId');
  }

  get videoId() {
    return this.get('videoId');
  }

  get videoUri() {
    return this.get('videoUri');
  }

  get description() {
    return this.get('description');
  }

  get name() {
    if (this.description.indexOf('sporting-event') !== -1) {
      const [se, ...name_] = this.description.split(' ');
      return name_.join(' ');
    } else {
      return this.description;
    }
  }

  getOtherAngles() {
    if (this.tags && this.tags?.sportingEventId) {
      const sportingEvent = SportingEventCollection.get(
        this?.tags?.sportingEventId
      );
      if (sportingEvent?.videoIds && sportingEvent?.videoIds?.length > 1) {
        this.angles = sportingEvent.videoIds
          .toJSON()
          .filter((v) => v != 'filtered');
        if (this.angles.length > 0) {
          return this.angles;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  isVideoFragmentFullVideo(videoFragment) {
    return (
      videoFragment.isFullMatch ||
      (videoFragment.startTime === 0 && videoFragment.endTime === this.duration)
    );
  }

  privilegedTo(action) {
    if (!this.has('_metadata')) {
      return false;
    }

    const privileges = this.get('_metadata').privileges;
    return privileges.indexOf(`video:${action}`) !== -1;
  }

  get isReady() {
    return this.state === 'ready';
  }
}

class Placeholder {
  getVideoFragmentUrlByVideoFagment() {
    return PLACEHOLDER_URL;
  }
  getOtherAngles() {
    return false;
  }
}

class VideoCollection extends PersistentCollection {
  url() {
    return '/videos';
  }

  model() {
    return Video;
  }

  getOrPlaceholder(videoId) {
    let video = this.get(videoId);
    if (!video || !video.mediaUrl) {
      video = new Placeholder();
    }
    return video;
  }
}

const videoCollection = new VideoCollection();
window.videoCollection = videoCollection;

export default videoCollection;
