import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Comment } from './comments/comment';
import sweetAlert from '../../../lib/sweetAlert';
import moment from 'moment';
import { useAssignmentContext } from '../assignments/assignmentContext';

export const TextTask = ({ task }) => {
  const [comment, setComment] = useState('');
  const assignmentContext = useAssignmentContext();

  const assignment = assignmentContext.getAssignmentByTaskID(task.taskId);
  // dependent query, make sure we have most recent data when invalidating or setting first time assignment.

  const versions = assignment?.versions ?? [];
  const activeVersion = versions.find((version) => !version.archived);

  const isTeacher = assignmentContext.isTeacher;
  const isTrainee = assignmentContext.isTrainee;

  const practice = assignmentContext.getPracticeByPracticeId(task.practiceId);

  async function onSubmit() {
    const data = {
      submissionTRN: 'trn:not-set',
      taskTRN: task.taskTRN,
      name: `${practice.name} - ${task.name}`,
      comment: comment,
    };
    return await assignmentContext.addVersion(data, assignment);
  }

  const { t } = useTranslation('module.development.tasks');

  return (
    <div className={'documentTask mt-3'}>
      {!activeVersion && isTrainee && (
        <div className="version-feedback mb-3">
          <label>
            <b>{t('answerAssignment')}</b>
          </label>
          <textarea
            rows={2}
            disabled={assignmentContext.isSubmitting}
            placeholder={t('answerAssignmentPlaceholder')}
            className={'form-control'}
            onChange={(e) => setComment(e.target.value)}
          />
          <button
            onClick={() => onSubmit()}
            disabled={!comment || assignmentContext.isSubmitting}
            className={'btn btn-primary mt-3'}
          >
            {t('form.submitText')}
          </button>
        </div>
      )}
    </div>
  );
};

export const TextTaskVersion = ({ version, task }) => {
  const assignmentContext = useAssignmentContext();
  const assignment = assignmentContext.getAssignmentByTaskID(task.taskId);
  // dependent query, make sure we have most recent data when invalidating or setting first time assignment.

  const versions = assignment?.versions ?? [];
  const activeVersion = versions.find((version) => !version.archived);

  const isTeacher = assignmentContext.isTeacher;
  const isTrainee = assignmentContext.isTrainee;

  const textAreaRef = useRef();

  function getComment() {
    return textAreaRef.current.value;
  }

  const accept = async () => {
    // post approve
    let feedback = getComment();
    assignmentContext.acceptVersion(assignment.assignmentId, feedback);
  };

  const requestChanges = async () => {
    let feedback = getComment();
    if (!feedback) {
      // Cannot submit without feedback..
      window.alert('geen feedback gegeven');
      return;
    }
    assignmentContext.requestChangesVersion(assignment.assignmentId, feedback);
  };

  const retract = async () => {
    sweetAlert({
      title: t('retractAssignmentWarning'),
      text: t('retractAssignmentMessage'),
      dangerMode: true,
      buttons: [t('common:cancel'), t('common:confirm')],
    }).then((willDelete) => {
      if (willDelete) {
        assignmentContext.retractVersion(assignment.assignmentId, null);
      } else {
      }
    });
  };

  const requestChangesButtonRef = useRef();

  const { t } = useTranslation('module.development.tasks');
  return (
    <div className={`version-sportingevent ${version.state}`}>
      <div className="version-video__titlebar">
        <div>{moment(version.updated).format('D-M-Y HH:mm')}</div>
        <div className="version__state">{t(version.state)} </div>
      </div>
      <div className="version-feedback">
        {(version.comment || version.assessmentComment) && (
          <div className="version-comment-row">
            {version.comment && (
              <Comment
                userId={version.submitterUserId}
                comment={version.comment}
              />
            )}
            {version.assessmentComment && (
              <Comment
                userId={version.assessorUserId}
                comment={version.assessmentComment}
              />
            )}
          </div>
        )}
      </div>
      {activeVersion &&
        activeVersion?.state !== 'accepted' &&
        isTeacher &&
        !activeVersion?.archived && (
          <div>
            <div className="version-actions">
              <div className="version-feedback">
                <label>{t('form.feedbackForTrainee')}</label>
                <textarea
                  ref={textAreaRef}
                  disabled={assignmentContext.isSubmitting}
                  className={'form-control'}
                  rows={3}
                  onChange={(e) => {
                    if (e.target.value) {
                      requestChangesButtonRef.current.disabled = false;
                    } else {
                      requestChangesButtonRef.current.disabled = true;
                    }
                  }}
                />
              </div>
              <button
                onClick={accept}
                disabled={assignmentContext.isSubmitting}
                className={'btn btn-primary'}
              >
                {t('form.approve')}
              </button>
              <button
                ref={requestChangesButtonRef}
                onClick={() => {
                  requestChanges();
                }}
                disabled={assignmentContext.isSubmitting}
                className={'btn btn-link'}
              >
                {t('form.requestChanges')}
              </button>
            </div>
          </div>
        )}
      {activeVersion &&
        activeVersion?.state !== 'accepted' &&
        isTrainee &&
        !activeVersion?.archived && (
          <div className="version-actions">
            <button
              disabled={assignmentContext.isSubmitting}
              onClick={retract}
              className={'btn btn-link'}
            >
              {t('retractAssignment')}
            </button>
          </div>
        )}
    </div>
  );
};
