import { FileUploader } from '../../../lib/fileUploader';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Session } from '../../../domain/Session';
import sweetAlert from '../../../lib/sweetAlert';
import { Comment } from './comments/comment';
import moment from 'moment';
import { downloadFile } from '../../../lib/fileUploader/filesHandler';
import { useAssignmentContext } from '../assignments/assignmentContext';
import { SubmittingButton } from '../../../lib/SubmittingButton';

const mimetypes = {
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/rtf': [],
  'text/plain': [],
  'application/vnd.oasis.opendocument.text': [],
  'application/pdf': [],
};

const maxDocumentSize = 20 * 1000 * 1000; // 20MB https://github.com/teamtv/teamtv-platform/pull/470#event-11490534092

export const DocumentTask = ({ task }) => {
  const [isUploading, setIsUploading] = useState();

  const assignmentContext = useAssignmentContext();
  const assignment = assignmentContext.getAssignmentByTaskID(task.taskId);

  const { t } = useTranslation('module.development.tasks');
  // Always fetch newest data when opening taskModal.
  // dependent query, make sure we have most recent data when invalidating or setting first time assignment.
  const currentSession = Session.current();

  const isTeacher = assignmentContext.isTeacher;
  const isTrainee = assignmentContext.isTrainee;

  const versions = assignment?.versions ?? [];
  const activeVersion = versions.find((version) => !version.archived);

  const archivedVersions = versions.filter((version) => version.archived);

  const textAreaRef = useRef();

  const practice = assignmentContext.getPracticeByPracticeId(task.practiceId);

  function getComment() {
    return textAreaRef.current.value;
  }

  const onUploadFile = async (files) => {
    setIsUploading(true);
    const data = {
      file: files[0],
      taskTRN: task.taskTRN,
      name: `${practice.name} - ${task.name}`,
      comment: getComment(),
    };

    assignmentContext.addVersion(data, assignment);
  };

  return (
    <div className={'documentTask'}>
      {!activeVersion && isTrainee && (
        <>
          <div className="version-feedback">
            <label>
              <b>{t('form.noteForTeacher')}</b>
            </label>
            <textarea
              placeholder={t('form.noteForTeacherPlaceholder', {
                type: `${t('file')}`,
              })}
              ref={textAreaRef}
              disabled={isUploading}
              className={'form-control'}
              rows={3}
            />
          </div>
          <label>
            <b>{t('form.uploadFile')}</b>
          </label>
          <FileUploader
            labels={{
              uploadFile: t('uploadFileButton'),
              allowedFiles: t('fileFormat', {
                formats: '.doc, .docx, .pdf, .txt, .odt',
              }),
            }}
            maxSize={maxDocumentSize} // 20MB
            filetypes={mimetypes}
            onUpload={onUploadFile}
          />
        </>
      )}
    </div>
  );
};

export const FileVersion = ({ version, assignment, openOnLoad }) => {
  const assignmentContext = useAssignmentContext();

  const isTeacher = assignmentContext.isTeacher;
  const isTrainee = assignmentContext.isTrainee;

  const textAreaRef = useRef();
  const rejectButtonRef = useRef();
  const requestChangesButtonRef = useRef();
  const { t } = useTranslation('module.development.tasks');

  function getVersionFeedback() {
    return textAreaRef.current.value;
  }
  const assignmentFiles = assignmentContext.assignmentFiles;

  const file = assignmentFiles.find((f) => f.fileTRN === version.submissionTRN);

  const accept = async (file) => {
    // post approve
    let feedback = getVersionFeedback();
    await assignmentContext.acceptVersion(
      assignment.assignmentId,
      feedback,
      file
    );
  };

  const reject = async () => {
    let feedback = getVersionFeedback();
    if (!feedback) {
      // Cannot submit without feedback..
      window.alert('geen feedback gegeven');
      return;
    }
    await assignmentContext.rejectVersion(assignment.assignmentId, feedback);
  };
  const requestChanges = async (file) => {
    let feedback = getVersionFeedback();
    if (!feedback) {
      // Cannot submit without feedback..
      window.alert('geen feedback gegeven');
      return;
    }
    await assignmentContext.requestChangesVersion(
      assignment.assignmentId,
      feedback,
      file
    );
  };
  const retract = async () => {
    sweetAlert({
      title: t('retractFileWarning'),
      text: t('retractFileMessage'),
      dangerMode: true,
      buttons: [t('common:cancel'), t('common:confirm')],
    }).then(async (willDelete) => {
      if (willDelete) {
        await assignmentContext.retractVersion(assignment.assignmentId, null);
      } else {
      }
    });
  };

  const TeacherFeedbackForm = () => {
    const [file, setFile] = useState();
    return (
      <div className="version-actions">
        <div className="version-feedback">
          <label>{t('form.feedbackForTrainee')}</label>
          <textarea
            ref={textAreaRef}
            disabled={assignmentContext.isSubmitting}
            className={'form-control'}
            rows={3}
            onChange={(e) => {
              if (requestChangesButtonRef?.current) {
                if (e.target.value) {
                  requestChangesButtonRef.current.disabled = false;
                } else {
                  requestChangesButtonRef.current.disabled = true;
                }
              }
            }}
          />
        </div>
        <div className="version-feedback">
          <label>{t('form.uploadFile')}</label>
          <FileUploader
            labels={{
              uploadFile: t('uploadFileButton'),
              allowedFiles: t('fileFormat', {
                formats: '.doc, .docx, .pdf, .txt, .odt',
              }),
            }}
            maxSize={maxDocumentSize} // 20MB
            filetypes={mimetypes}
            onDropFiles={(acceptedFiles) => {
              setFile(acceptedFiles[0]);
            }}
          />
        </div>
        <button
          onClick={() => accept(file)}
          disabled={assignmentContext.isSubmitting}
          className={'btn btn-primary'}
        >
          {t('form.approve')}
        </button>
        {false && (
          <button
            ref={rejectButtonRef}
            onClick={() => {
              reject();
            }}
            disabled={assignmentContext.isSubmitting}
            className={'btn btn-primary btn-danger'}
          >
            {t('form.reject')}
          </button>
        )}
        <button
          ref={rejectButtonRef}
          onClick={() => {
            requestChanges(file);
          }}
          disabled={assignmentContext.isSubmitting}
          className={'btn btn-link'}
        >
          {t('form.requestChanges')}
        </button>
      </div>
    );
  };
  const _feedbackFile = assignmentFiles.find(
    (f) => f.fileTRN === version?.assessmentFeedbackTRN
  );

  return (
    <>
      <div className={`version__document ${version.state}`}>
        <div className="version-file-row ">
          <div className="version-file">
            <button
              onClick={async () => {
                await downloadFile(file.$urls.download);
              }}
              className={'btn btn-link'}
            >
              <i className={'i-export i-sm'} />
              <span>{moment(version.created).format('D-M-Y')}</span>
              <span>- {file?.key.split('/')[1]}</span>
            </button>
          </div>
          <div className="version__state">{t(version.state)} </div>
        </div>

        {(version.comment || version.assessmentComment) && (
          <div className="version-comment-row">
            {version.comment && (
              <Comment
                userId={version.submitterUserId}
                comment={version.comment}
              />
            )}
            {version.assessmentComment && (
              <Comment
                userId={version.assessorUserId}
                comment={version.assessmentComment}
              />
            )}
            {version.assessmentFeedbackTRN && (
              <Comment
                userId={version.assessorUserId}
                comment={
                  <a
                    onClick={async () => {
                      await downloadFile(_feedbackFile.$urls.download);
                    }}
                    className={'link'}
                  >
                    <i className={'i-export i-xs'}></i>{' '}
                    {_feedbackFile.key.split('/')[1]}
                  </a>
                }
              />
            )}
          </div>
        )}
      </div>
      {version.state !== 'accepted' && isTeacher && !version.archived && (
        <TeacherFeedbackForm />
      )}
      {version.state !== 'accepted' && isTrainee && !version.archived && (
        <div className="version-actions">
          <SubmittingButton
            disabled={assignmentContext.isSubmitting}
            onClick={retract}
            className={'btn btn-link'}
          >
            {t('retractFile')}
          </SubmittingButton>
        </div>
      )}
    </>
  );
};
