import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { asyncAction } from 'mobx-utils';

import { Session } from 'domain/Session';
import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Page } from 'lib/Page';

import videoCollection from 'domain/Video';

import { LearningLineDetails } from '../components/learning-line-details';
import { DevelopmentPlanQueryService } from 'domain/Development/DevelopmentPlanQueryService';
import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';
import { withTranslation } from 'react-i18next';
import { Error } from 'lib/PlaceHolder';

import sportingEventCollection from 'domain/SportingEvent/SportingEvent';
import { DaysAgo } from '../../../lib/DaysAgo';

const TDPUpdatePage = withTranslation('module.development')(
  observer(
    class TDPUpdatePage extends Component {
      constructor(props) {
        super(props);
        this.dataState = observable('loading');
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield Session.current().isReady();

          const currentSession = Session.current();

          yield currentSession.isReady();
          yield videoCollection.fetchIfEmpty();
          yield sportingEventCollection.fetchIfEmpty();

          this.developmentPlan =
            yield DevelopmentPlanQueryService.developmentPlanByResourceGroup();

          if (!this.developmentPlan.isNew) {
            yield this.developmentPlan.videoFragments.fetch();
          }

          this.learningLine = yield LearningLineQueryService.learningLineById(
            this.props.match.params.learningLineId
          );
          yield this.learningLine.videoFragments.fetch();

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load team plan',
          });
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            {
              path: '/development/tdp/detail',
              title: this.props.t('development'),
            },
            { title: this.learningLine.name },
          ],
          className: 'page--development development-update',
        };

        return (
          <Page {...pageProps}>
            <LearningLineDetails
              match={this.props.match.params}
              learningLine={this.learningLine}
              developmentPlan={this.developmentPlan}
            />
          </Page>
        );
      }
    }
  )
);

export { TDPUpdatePage };
