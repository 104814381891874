import { VideoTaskVersion } from './VideoTask';
import { Session } from '../../../domain/Session';
import { useTranslation } from 'react-i18next';
import { FileVersion } from './DocumentTask';
import { ReadingTaskVersion } from './ReadingTask';
import { useAssignmentContext } from '../assignments/assignmentContext';
import { QuestionAnswerVersion } from './QuestionAnswerTask';
import { TextTask, TextTaskVersion } from './TextTask';

export const Versions = ({ task }) => {
  const assignmentContext = useAssignmentContext();
  const assignment = assignmentContext.getAssignmentByTaskID(task.taskId);
  const activeVersion = assignment?.versions.find(
    (version) => !version.archived
  );
  const archivedVersions =
    assignment?.versions
      .filter((version) => version.archived)
      .sort((a, b) => b.updated.localeCompare(a.updated)) ?? [];
  const isTeacher = assignmentContext.isTeacher;
  const isTrainee = assignmentContext.isTrainee;
  const { t } = useTranslation('module.development.tasks');

  if (task.taskType === 'read') {
    return <ReadingTaskVersion assignment={assignment} />;
  }

  return (
    <div>
      {activeVersion && (
        <GetTaskType
          task={task}
          version={activeVersion}
          assignment={assignment}
        />
      )}
      {!activeVersion && !isTrainee && (
        <div>
          <h5>{t('noSubmissions')}</h5>
        </div>
      )}
      {archivedVersions.length > 0 && (
        <div className={'archivedVersions'}>
          <h3>{t('previousSubmission')}</h3>
          {archivedVersions.map((version) => {
            return (
              <GetTaskType
                key={version.versionId}
                task={task}
                version={version}
                assignment={assignment}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const GetTaskType = ({ task, version, assignment }) => {
  // Let Submission declare which component is returned.
  if (
    version?.submissionType === 'video' ||
    version.submissionTRN.includes('sportingevent')
  ) {
    return (
      <VideoTaskVersion
        version={version}
        assignment={assignment}
        openOnLoad={!version.archived}
      />
    );
  }
  if (
    version?.submissionType === 'read' ||
    version.submissionTRN.includes('learningLine')
  ) {
    return <ReadingTaskVersion assignment={assignment} />;
  }
  if (
    version?.submissionType === 'question_answer' ||
    (version.submissionTRN.includes('not-set') && task?.answer)
  ) {
    return (
      <QuestionAnswerVersion
        task={task}
        assignment={assignment}
        version={version}
      />
    );
  }
  if (
    version?.submissionType === 'text' ||
    (version.submissionTRN.includes('not-set') && !task?.answer)
  ) {
    return <TextTaskVersion version={version} task={task} />;
  }
  if (
    version?.submissionType === 'document' ||
    version.submissionTRN.includes('bucket')
  ) {
    return (
      <FileVersion
        version={version}
        assignment={assignment}
        openOnLoad={!version.archived}
      />
    );
  }
};
