import React, { Fragment, Component, useState, useEffect } from 'react';
import {
  Route as ReactRoute,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom';

import PrivateRoute from '../lib/PrivateRoute';

import history from '../utils/History';
import registry from './registry';

import { Session } from 'domain/Session';
import { NoLicense } from 'lib/PlaceHolder';
import Loading from 'lib/Loading';

import Authentication from '../utils/Authentication';
import { MainPageErrorFallback } from 'lib/PlaceHolder';
import logger from 'utils/Logger';

export const DeeplinkHandler = ({ match }) => {
  const { code, deeplink } = match.params;
  const { action, resourceGroupId, ...args } = JSON.parse(atob(deeplink));

  /*

   {"action":"upload-video", "sportingEventId": "9dbc50fb-126f-49e8-8281-12e092380117", "resourceGroupId": "79c94118-e070-11e8-b7ae-6c4008b541b8"}

   */

  useEffect(() => {
    (async () => {
      await Authentication.setTokenFromLoginTokenCode(code);
      const session = Session.current();

      let location;
      switch (action) {
        case 'observe-video':
          location = getRoutePath('observe.video-start', {
            sportingEventId: args.sportingEventId,
            observationInputKey:
              args.observationInputName ||
              args.observationInputKey ||
              'default',
          });
          break;

        case 'reporting':
          location = getRoutePath('reporting.detail', {
            sportingEventId: args.sportingEventId,
          });
          break;

        case 'upload-video':
          location = getRoutePath('match.overview-upload', {
            timeFilterName: 'past',
            uploadVideoSportingEventId: args.sportingEventId,
          });
          break;
        default:
          location = '/';
          break;
      }

      session.setResourceGroupId(resourceGroupId, location);
    })();
  }, [
    action,
    args.observationInputName,
    args.sportingEventId,
    code,
    resourceGroupId,
  ]);
  return null;
};

const WhenNotFound = () => {
  logger.warning(`pageNotFound`, {
    currentUrl: window.location.href,
    referrer: document.referrer,
    full: window.location.toString(),
  });
  console.log({
    currentUrl: window.location.href,
    referrer: document.referrer,
    full: window.location.toString(),
  });
  return <MainPageErrorFallback errorType={'pageNotFound'} />;
};

class RouteRedirect {
  constructor(fromRelativePath, toRelativePath, routeName) {
    this.fromRelativePath = fromRelativePath;
    this.toRelativePath = toRelativePath;
    this.routeName = routeName;
  }
}

class Route {
  constructor(relativePath, component, routeName, options = null) {
    if (!routeName) {
      throw 'Route should have a name: ' + relativePath;
    }
    this.relativePath = relativePath;
    this.component = component;
    this.routeName = routeName;

    const {
      protectedRoute = true,
      defaultArgs = {},
      defaultProps = {},
    } = options || {};
    this.protectedRoute = protectedRoute;
    this.defaultArgs = defaultArgs;
    this.defaultProps = defaultProps;
  }

  get isExact() {
    return false;
  }
}

class RouteExact extends Route {
  get isExact() {
    return true;
  }
}

class PromiseRedirect extends Component {
  constructor() {
    super();

    this.state = {
      to: null,
    };
  }

  async setPath() {
    const path = await this.props.to(this.props.computedMatch.params);

    this.setState({ to: path });
  }

  componentDidMount() {
    if (typeof this.props.to === 'function') {
      this.setPath();
    }
  }

  render() {
    let to;
    if (typeof this.props.to === 'function') {
      // promise, lets wait for state to be fixed
      if (this.state.to === null) {
        return null;
      }

      to = this.state.to;
    } else {
      to = this.props.to;
    }

    return <Redirect {...this.props} to={to} />;
  }
}

const SubRoute = ({ moduleName, otherProps: props }) => {
  const [isReady, setReady] = useState(false);
  const [licensePrivileges, setLicensePrivileges] = useState(false);

  const session = Session.current();

  useEffect(() => {
    const waiter = async () => {
      await session.isReady();
      setLicensePrivileges(session.licensePrivileges());
      setReady(true);
    };
    waiter();
  }, [session]);

  const LoadingComponent = () => {
    return <Loading type={'fullscreen'} />;
  };

  const ROOT = props.match.path;

  const module = registry.getModuleByName(moduleName);
  return (
    <Switch>
      {module.routingItems.map((routingItem) => {
        switch (true) {
          case routingItem instanceof RouteRedirect:
            return (
              <PromiseRedirect
                key={routingItem.fromRelativePath}
                exact
                from={`${ROOT}/${routingItem.fromRelativePath}`}
                to={
                  typeof routingItem.toRelativePath === 'function'
                    ? routingItem.toRelativePath
                    : `${ROOT}/${routingItem.toRelativePath}`
                }
              />
            );

          case routingItem instanceof Route:
            if (routingItem.protectedRoute) {
              let component;

              if (!isReady) {
                component = LoadingComponent;
              } else {
                const hasRequiredPrivileges =
                  module.requiredPrivileges.length === 0 ||
                  licensePrivileges.hasPrivilege(module.requiredPrivileges[0]);

                if (hasRequiredPrivileges) {
                  component = routingItem.component;
                } else {
                  component = NoLicense;
                }
              }

              return (
                <PrivateRoute
                  key={routingItem.relativePath}
                  exact={routingItem.isExact}
                  path={`${ROOT}/${routingItem.relativePath}`}
                  component={component}
                  defaultProps={routingItem.defaultProps}
                />
              );
            } else {
              return (
                <ReactRoute
                  key={routingItem.relativePath}
                  exact={routingItem.isExact}
                  path={`${ROOT}/${routingItem.relativePath}`}
                  component={routingItem.component}
                />
              );
            }

          default:
            throw 'Unknown routingItem';
        }
      })}
      <ReactRoute component={WhenNotFound} />
    </Switch>
  );
};

const MainRoute = () => {
  return (
    <Switch>
      {registry.modules.map((module) => {
        return (
          <ReactRoute
            key={module.name}
            path={`/${module.name}`}
            render={(props) => (
              <SubRoute moduleName={module.name} otherProps={props} />
            )}
          />
        );
      })}
      <ReactRoute
        exact={true}
        path="/deeplink/:code/:deeplink"
        component={DeeplinkHandler}
      />
      <ReactRoute component={WhenNotFound} />
    </Switch>
  );
};

const replacer = function (tpl, data) {
  // Replace placeholders with corresponding data values
  var path = tpl.replace(/:([^\/\?]+)/g, function ($1, $2) {
    return data[$2] !== undefined && data[$2] !== null ? data[$2] : '';
  });

  // Remove segments with remaining placeholders that were not replaced
  path = path.replace(/\/:([^\/]+)\?/g, '');

  // Remove any double slashes that may result from empty optional parameters
  path = path.replace(/\/+/g, '/');

  // Ensure the path does not end with a slash (unless it's the root '/')
  path = path.replace(/\/$/, '');

  return path;
};

const getRoutePath = (fullRouteName, routeArgs = {}) => {
  let [moduleName, routeName] = fullRouteName.split('.');
  const module = registry.getModuleByName(moduleName);

  for (const route of module.routingItems) {
    if (route.routeName === routeName) {
      const args = {};
      Object.assign(args, route.defaultArgs);
      Object.assign(args, routeArgs);
      return `/${moduleName}/${replacer(
        route.relativePath || route.fromRelativePath,
        args
      )}`;
    }
  }
  return undefined;
};

const gotoPath = (path) => {
  history.push(path);
};

const gotoRoute = (fullRouteName, routeArgs = {}) => {
  const path = getRoutePath(fullRouteName, routeArgs);
  if (path === undefined) {
    throw 'Unknown route ' + fullRouteName;
  }
  gotoPath(path);
  return true;
};

const goBack = () => {
  history.goBack();
};

export {
  Route,
  RouteExact,
  RouteRedirect,
  MainRoute,
  gotoRoute,
  gotoPath,
  goBack,
  getRoutePath,
};
